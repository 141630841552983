import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { FaCheck, FaExclamationTriangle, FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import { DataContext } from "../../../../Context/dataContext";
import { postRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { COLORS } from "../../../../Styles/colors";

const ScoringExcelModal = ({ uuid, setOpenExcelModal, campaign }) => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const [file, setFile] = useState(null);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [step, setStep] = useState(0); // Step 0: File Upload, Step 1: Results
  const [results, setResults] = useState(null); // To store the results of the upload
  const customer = useAppSelector((state) => state.customer);

  // ----- FUNCTIONS -----
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name.toLowerCase();
      if (fileName.endsWith(".xlsx")) {
        setFile(selectedFile);
      } else {
        alert("Please select a valid XLSX file.");
        e.target.value = null; // Clear the file input
      }
    }
  };

  const handleSubmit = async () => {
    if (!file) {
      alert("Please select a valid XLSX file.");
      return;
    }

    setSubmitBtnLoading(true);
    const data = new FormData();
    data.append("file", file);

    const loadingToast = toast.loading("Uploading XLSX Content");
    try {
      const url = campaign
        ? `/react/api/${customer.uuid}/campaign/scoring_excel/${uuid}`
        : `/react/api/${customer.uuid}/projects/scoring_excel/${uuid}`;
      const res = await postRequest(url, accessToken, data);

      if (res.status === 200) {
        toast.dismiss(loadingToast);
        const {
          updated_count,
          no_changes_count,
          skipped_count,
          missing_security_tool,
        } = res.data;

        // Prepare the results to display in the next step
        setResults({
          updated_count,
          no_changes_count,
          skipped_count,
          missing_security_tool,
        });

        // Move to Step 1 (Results View)
        setStep(1);
      } else {
        toast.dismiss(loadingToast);
        toast.error(
          "Error uploading XLSX Content, please save file in enabled editing format and try again."
        );
        setOpenExcelModal(false);
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error("Error Uploading XLSX Content");
    } finally {
      setSubmitBtnLoading(false);
    }
  };

  // ----- RESULTS VIEW -----
  const renderResults = () => {
    if (!results) return null;

    const {
      updated_count,
      no_changes_count,
      skipped_count,
      missing_security_tool,
    } = results;

    return (
      <Box mt={2}>
        <Typography variant="h6" align="center">
          Upload Results
        </Typography>
        <Divider />

        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Card elevation={3} sx={{ backgroundColor: COLORS.success.light }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Typography variant="h4" color={COLORS.success.main} mr={1}>
                    {updated_count}
                  </Typography>
                  <Typography variant="body1" flexGrow={1}>
                    {updated_count > 0
                      ? "Findings updated successfully"
                      : "No changes detected"}
                  </Typography>
                  <FaCheck color={COLORS.success.main} size={24} />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card elevation={3} sx={{ backgroundColor: COLORS.warning.light }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Typography variant="h4" color={COLORS.warning.main} mr={1}>
                    {no_changes_count}
                  </Typography>
                  <Typography variant="body1" flexGrow={1}>
                    Findings had no changes
                  </Typography>
                  <FaExclamationTriangle
                    color={COLORS.warning.main}
                    size={24}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card elevation={3} sx={{ backgroundColor: COLORS.error.light }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Typography variant="h4" color={COLORS.error.main} mr={1}>
                    {missing_security_tool}
                  </Typography>
                  <Typography variant="body1" flexGrow={1}>
                    Findings missing Security Tool
                  </Typography>
                  <FaTimesCircle color={COLORS.error.main} size={24} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box mt={3} display="flex" justifyContent="space-between">
          <Button variant="contained" onClick={() => setStep(0)}>
            Back to Upload
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenExcelModal(false);
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    );
  };

  // ----- CONDITIONAL RENDERING -----
  return (
    <Paper elevation={3} sx={{ padding: 2, maxWidth: "600px", margin: "auto" }}>
      <Stepper activeStep={step} alternativeLabel>
        <Step>
          <StepLabel>Upload File</StepLabel>
        </Step>
        <Step>
          <StepLabel>Results</StepLabel>
        </Step>
      </Stepper>

      {step === 0 ? (
        <>
          <Typography variant="h5">
            Upload Scoring Excel File (XLSX only)
          </Typography>

          <Box mt={2}>
            <TextField
              type="file"
              fullWidth
              variant="outlined"
              InputProps={{
                inputProps: {
                  accept: ".xlsx",
                },
              }}
              onChange={handleFileChange}
            />
          </Box>

          <Box mt={2}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={submitBtnLoading}
            >
              {submitBtnLoading ? "Uploading..." : "Upload File"}
            </Button>
          </Box>
        </>
      ) : (
        renderResults()
      )}
    </Paper>
  );
};

export default ScoringExcelModal;
import React, { useState, useEffect, useContext } from "react";
import {
  Stack,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { postRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";

const CrowdstrikeConfigForm2 = (props) => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const [securityTools, setSecurityTools] = useState([]);
  const [clientSecret, setClientSecret] = useState("");
  const [showClientScret, setShowClientSecret] = useState(false);
  const [baseUrl, setBaseUrl] = useState("");
  const [falconUrl, setFalconUrl] = useState("");
  const [clientId, setClientId] = useState("");
  const [uuid, setUUID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { onFinish, setModalOpen, toolData } = props;
  const [selectedSecurtiyTool, setSelectedSecurityTool] = useState(
    toolData.uuid
  );

  // ----- VARIABLES -----
  // Required Prop

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    const data = {
      securityTool: selectedSecurtiyTool,
      base_url: baseUrl,
      falcon_base_url: falconUrl,
      client_id: clientId,
      client_secret: clientSecret,
      uuid: uuid,
    };

    if (toolData.action === "edit") {
      toast.info("Attempting to edit CrowdStrike config");
      const result = await postRequest(
        "/react/api/config/securitytoolconfigs/crowdstrike/edit",
        accessToken,
        data
      );
      if (result.data.status === 200) {
        toast.success("CrowdStrike Config editted Successfully");
        setModalOpen(false);
        if (onFinish) {
          onFinish();
        }
      } else {
        toast.error("Failed to edit crowdstrike config");
        setErrorMessage(`${result.data.status} - ${result.data.message}`);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000); // wait 5 seconds before clearing the error message
      }
    }

    if (toolData.action === "add") {
      toast.info("Attempting to add CrowdStrike config");
      const result = await postRequest(
        "/react/api/config/securitytoolconfigs/crowdstrike/add",
        accessToken,
        data
      );
      if (result.data.status === 200) {
        toast.success("CrowdStrike Config Added Successfully");
        setModalOpen(false);
        if (onFinish) {
          onFinish();
        }
      } else {
        toast.error("Failed to add crowdstrike config");
        setErrorMessage(`${result.data.status} - ${result.data.message}`);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000); // wait 5 seconds before clearing the error message
      }
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const setForm = async () => {
      setSecurityTools(toolData.securityOptions);
      if (toolData.action === "edit") {
        setSelectedSecurityTool(toolData.tool.uuid);
        setBaseUrl(toolData.tool.tool_config.base_url);
        setFalconUrl(toolData.tool.tool_config.falcon_base_url);
        setClientId(toolData.tool.tool_config.client_id);
        setClientSecret(toolData.tool.tool_config.client_secret);
        setUUID(toolData.tool.tool_config.uuid);
      }
    };
    setForm();
  }, [accessToken, toolData]);

  return (
    <Stack>
      <Typography variant="h5">Crowdstrike Integration Details</Typography>
      <Divider style={{ margin: "1rem 0" }} />

      <Stack spacing={2}>
        {/* Simulation */}
        <FormControl>
          <InputLabel id="alert-simulation">Security Tool</InputLabel>
          <Select
            labelId="alert-security-tool"
            id="alert-security-tool-select"
            value={selectedSecurtiyTool ? selectedSecurtiyTool : ""}
            label="Security Tool"
            disabled={toolData.uuid}
            onChange={(e) => setSelectedSecurityTool(e.target.value)}
          >
            {securityTools
              ? securityTools.map((tool) => (
                  <MenuItem key={tool.uuid} value={tool.uuid}>
                    {tool.name}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>

        {/* Base Url */}
        <TextField
          required
          id="tool-azure-resource-group"
          label="Base Url"
          value={baseUrl}
          onChange={(e) => setBaseUrl(e.target.value)}
        />

        {/* Falcon Url */}
        <TextField
          required
          id="tool-azure-resource-group"
          label="Falcon Url"
          value={falconUrl}
          onChange={(e) => setFalconUrl(e.target.value)}
        />

        {/* Workspace Name */}
        <TextField
          required
          id="tool-azure-workspace-name"
          label="Client Id"
          value={clientId}
          onChange={(e) => setClientId(e.target.value)}
        />

        {/* Client Secret */}
        <TextField
          required
          id="tool-azure-subscription"
          label="Client Secret"
          type={showClientScret ? "text" : "password"}
          value={clientSecret}
          onChange={(e) => setClientSecret(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowClientSecret(!showClientScret)}
                >
                  {showClientScret ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          onClick={handleSubmit}
          variant="contained"
          color={errorMessage ? "error" : "primary"}
        >
          {errorMessage ? errorMessage : "Submit"}
        </Button>
      </Stack>
    </Stack>
  );
};

CrowdstrikeConfigForm2.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
};

export default CrowdstrikeConfigForm2;

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  CircularProgress,
  Box
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { FileSelect } from "../../../../Components";
import { DataContext } from "../../../../Context/dataContext";
import { postRequest } from "../../../../Helpers/httpRequests";
import { useHttpRequest } from "../../../../Hooks";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  p: 3,
};

export default function UploadLogs({ open, setOpen, selectedCampaignUUID, setReload }) {
  const { accessToken } = useContext(DataContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({
    logFile: null,
    campaignUUID: selectedCampaignUUID,
  });
  const handleClose = () => setOpen(false);
  const [toolSelection, setToolSelection] = useState([]);

  const { response: tools } = useHttpRequest({
    method: "get",
    path: "/api/v2/security-tool",
    params: { log_scoring: true },
  });

  const handleSubmit = async () => {
    setIsProcessing(true);
    const res = await postRequest(
      "/react/api/campaigns/score-log-file",
      accessToken,
      {
        ...formData,
        campaignUUID: selectedCampaignUUID,
        toolName: toolSelection,
      }
    );
    setIsProcessing(false);

    if (res.status === 200) {
      // do teh stuffs like show confirmation msg
      toast.success("Scored");
      handleClose();
      setReload((prev) => !prev);
    }
    return;
  };

  const toolFields = () => {
    switch (toolSelection) {
      case "Crowdstrike":
        return (
          <>
            <FileSelect
              formKey="telemetryFile"
              setFormData={setFormData}
              label="Telemetry File"
              accept=".json"
            />
            <FileSelect
              formKey="detectionFile"
              setFormData={setFormData}
              label="Detection File"
              accept=".json"
            />
          </>
        );
      case "Defender":
        return (
          <FileSelect
            formKey="logFile"
            setFormData={setFormData}
            label="Log File"
            accept=".csv"
          />
        );
      default:
        return <></>;
    }
  };

  const disableSubmit = () => {
    if (toolSelection === "Crowdstrike") {
      return formData.telemetryFile === null && formData.detectionFile === null;
    } else if (toolSelection === "Defender") {
      return formData.logFile === null;
    } else {
      return true;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Stack spacing={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Autoscoring with security tool logs
          </Typography>
          {tools?.length > 0 ? (
            <Tooltip title="Supported Tools for Log Scoring">
              <FormControl>
                <InputLabel id="tool-select-label">Security Tool</InputLabel>
                <Select
                  labelId="tool-select-label"
                  value={toolSelection}
                  label="Security Tool"
                  onChange={(e) => setToolSelection(e.target.value)}
                >
                  {tools.map((tool) => (
                    <MenuItem value={tool.product}>{tool.product}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
          ) : (
            <Typography>
              No Available Tools for Log Scoring. Currently Supported Tools are:
              Microsoft Defender, Crowdstrike
            </Typography>
          )}

          {isProcessing ? ( // Conditionally render CircularProgress and text
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CircularProgress />
              <Typography variant="body1" sx={{ mt: 2 }}>Processing log files...</Typography>
            </Box>
          ) : (
          <>
          {toolSelection && toolFields()}

          <Stack direction="row" justifyContent="space-evenly" spacing={2}>
            <Button
              disabled={disableSubmit()}
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "gray",
                ":hover": { backgroundColor: "gray" },
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Stack>
          </>
          )}
        </Stack>
      </Paper>
    </Modal>
  );
}

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import { useContext, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";

const SentinelConfig2 = (props) => {
  const { data, handleEditOrAdd, setSentinelConfig, setHasSentinel } = props;
  const [showSubId, setShowSubId] = useState(false);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );
  // const [sentinelRes, setSentinelRes] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteSentinelConfig = async () => {
    const res = await toast.promise(
      postRequest(
        `/react/api/${current_customer.uuid}/config/delete_tool/${data.uuid}`,
        accessToken
      ),
      {
        pending: "Deleting Sentinel Config",
        success: `Sentinel Config Deleted`,
        error: "Something went wrong!",
      }
    );
    if (res.status === 204) {
      setSentinelConfig(false);
      setHasSentinel(false);
    }
  };

  // Replace every character of the client secret with a star character
  let hiddenSubId;
  let config;
  const handleShowSubId = () => {
    setShowSubId((prev) => !prev);
  };
  if (data.tool_config != null) {
    hiddenSubId = data.tool_config.azure_subscription_id.replace(/./g, "*");
    config = {
      "Security Tool:": data.name,
      "Azure Subscription ID:": (
        <Stack direction="row">
          <Typography>
            {showSubId ? data.tool_config.azure_subscription_id : hiddenSubId}
          </Typography>
          <IconButton onClick={handleShowSubId}>
            {showSubId ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Stack>
      ),
      "Azure Resource Group:": data.tool_config.azure_resource_group,
      "Azure Workspace Name:": data.tool_config.sentinel_workspace_name,
    };
  }

  // const testSentinel = useMemo(
  //   () => async () => {
  //     if (data.tool_config?.uuid) {
  //       const res = await getRequest(
  //         `/react/api/${current_customer.uuid}/testsentinel/${data.tool_config.uuid}`,
  //         accessToken
  //       );
  //       if (res.status === 200) {
  //         setSentinelRes(res.data);
  //       } else {
  //         toast.error(`Error - ${res.data.statuscode}, ${res.data.message}`);
  //       }
  //       setIsLoading(false);
  //     }
  //   },
  //   [data, accessToken]
  // );
  //
  // useEffect(() => {
  //   testSentinel();
  // }, [current_customer, testSentinel]);

  return (
    <>
      <Paper sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        {/* First Half */}
        <Stack
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 3,
            flex: 1,
            height: "100%",
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">
              Microsoft Sentinel Configuration
            </Typography>
            {/*{isLoading ? (*/}
            {/*  <CircularProgress size={30} />*/}
            {/*) : (*/}
            {/*  Object.keys(sentinelRes)?.length > 0 && (*/}
            {/*    <>*/}
            {/*      {sentinelRes.status === "success" ? (*/}
            {/*        <Chip size="medium" label="CONNECTED" color="success" />*/}
            {/*      ) : (*/}
            {/*        <Chip*/}
            {/*          size="medium"*/}
            {/*          label="FAILED TO CONNECT"*/}
            {/*          color="error"*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    </>*/}
            {/*  )*/}
            {/*)}*/}
          </Stack>
          <Divider />
          <Table>
            <TableBody>
              {config && Object.entries(config).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell sx={{ borderBottom: "none", fontWeight: "bold" }}>
                    {key}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Second Half */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {/* <Typography variant="h5">Connection Information</Typography>
            <Divider style={{ marginTop: "0.5rem" }} />
            {Object.keys(sentinelRes)?.length !== 0 && (
              <>
                <div>
                  <Typography variant="body1" sx={{ marginTop: 1 }}>
                    Connectors: {sentinelRes.connectors?.length}
                  </Typography>
                  <ul>
                    {sentinelRes?.connectors?.map((connector, index) => (
                      <li key={index}>{connector}</li>
                    ))}
                  </ul>
                </div>
              </>
            )} */}
          </Box>
          <Box
            sx={{
              width: "100%",
              textAlign: "right",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => handleEditOrAdd("sentinel", "edit", data)}
              title="Edit"
            >
              <FaEdit />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleteModal(true);
              }}
              title="Delete Sentinel Config"
            >
              <FaTrashAlt color={COLORS.error.main} />
            </IconButton>
          </Box>
        </Stack>
      </Paper>
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove Sentinel Config ?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deleteSentinelConfig()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
    </>
  );
};

export default SentinelConfig2;

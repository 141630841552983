import { useAuth0 } from "@auth0/auth0-react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  Backdrop,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { FaBell, FaDownload, FaInfo, FaRegMoon, FaTimes } from "react-icons/fa";
import { FiSun } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProfileAvatar from "../../Components/Avatars/ProfileAvatar";
import OutdatedAgentAlert from "../../Components/OutdatedAgentAlert/OutdatedAgentAlert";
import { DataContext } from "../../Context/dataContext";
import { getRequest } from "../../Helpers/httpRequests";
import blindspotPNG from "../../Images/logo.png";
import { toggleDarkMode } from "../../Redux/features/darkmode/darkmodeSlice";
import { updateUser } from "../../Redux/features/user/userSlice";
import Register from "../Unprotected/Register/Register";
import CustomerSelect from "./CustomerSelect";
import Navigation from "./Navigation/Navigation";

// for notifications
import "react-toastify/dist/ReactToastify.css";
import AlertsAvatar from "../../Components/Avatars/AlertsAvatar/AlertsAvatar";
import Splashscreen from "../../Components/BlindspotSplashScreen/Splashscreen/Splashscreen";
import Agreements from "../Agreements/Agreements";
import ClarityScript from "./ClarityScript";

// utils
import AdminAddNames from "../../Components/Forms/Administration/AdminAddNames/AdminAddNames";
import { isUUID } from "../../Helpers/functions";
import { useVerifyCustomerAccess, useVisibility } from "../../Hooks";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { selectCustomer } from "../../Redux/features/customer/customerSlice";
import ArchivedUserLanding from "../ArchivedUser/ArchivedUserLanding";
import AvOnlyNavigation from "./AVOnlyNavigation/AvOnlyNavigation";
import SMVNavigation from "./SMV Navigation/SMVNavigation";
import { AppBar, Drawer, DrawerHeader } from "./Utilities";

const SiteShell = ({ children }) => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  let imageDomain;
  if (env === "PROD") {
    imageDomain = process.env.REACT_APP_PROD_BASEURL;
  } else {
    imageDomain = process.env.REACT_APP_DEV_BASEURL;
  }
  const user = useAppSelector((state) => state.user);
  const darkmode = useAppSelector((state) => state.darkmode);
  const current_customer = useAppSelector((state) => state.customer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isVisible = useVisibility();
  const verifyCustomerAccess = useVerifyCustomerAccess();

  const [seleniumNav, setSeleniumNav] = useState("");
  const [showSeleniumNav, setShowSeleniumNav] = useState(false);
  const [requestedCustomerUUID, setRequestedCustomer] = useState(null);

  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [reload, setReload] = useState(false);
  const [initalLoad, setInitialLoad] = useState(true);
  const { accessToken, setAccessToken, logo, setLogo } =
    useContext(DataContext);
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  const handleClick = () => {
    setIsDrawerOpen((curr) => !curr);
    setNotificationCount(0);
  };

  // Selenium
  useEffect(() => {
    const callback = (event) => {
      if (event.ctrlKey && event.shiftKey && event.code === "Digit5") {
        setShowSeleniumNav(true);
      }
    };
    document.addEventListener("keydown", callback);
    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, []);

  // Intial Site Load - Getting User and Access Token
  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently({
        audience: "https://blindspotreact.com/api",
        scope: "read:everything",
      });
      return token;
    };

    /**
     * This function is for getting the current logged in user and setting it globally using Redux
     * @param {String} token
     */
    const getBlindspotUser = async (token) => {
      const res = await getRequest(`/react/api/get-bsuser`, token);
      if (res.status === 200) {
        const bsUser = res.data.user;
        dispatch(updateUser(bsUser));
      }
    };
    if (
      initalLoad &&
      isAuthenticated &&
      !isLoading &&
      !user.isLoaded &&
      !accessToken
    ) {
      getAccessToken().then((token) => {
        setAccessToken(token);
        Cookies.set("token", token);
        getBlindspotUser(token);
        setInitialLoad(false);
      });
    }
  }, [
    getAccessTokenSilently,
    setAccessToken,
    dispatch,
    reload,
    accessToken,
    isLoading,
    isAuthenticated,
    user.isLoaded,
    initalLoad,
  ]);

  // Getting and Setting Initial Load for Customer
  useEffect(() => {
    const getCustomerUUID = () => {
      const pathParts = window.location.pathname.split("/");
      if (isUUID(pathParts[1])) {
        setRequestedCustomer(pathParts[1]);
        return pathParts[1];
      }
      setRequestedCustomer(user.primary_customer.uuid);
      for (let i = 0; i < pathParts.length; i++) {
        if ("profile" === pathParts[i]) {
          return user.current_customer.uuid;
        }
      }
      return user.primary_customer.uuid;
    };
    const getNotificationCount = async (token, customer) => {
      const res = await getRequest(
        `/react/api/${customer.uuid}/getNotificationCount`,
        token
      );
      if (res.status === 200) {
        setNotificationCount(res.data.count);
      }
    };

    const getCustomerLogo = async (token, customer) => {
      const res = await getRequest(
        `/react/api/${customer.uuid}/admin/customer-logo`,
        token
      );
      if (res.status === 200) {
        setLogo(
          <img
            src={imageDomain + res.data.logo}
            style={{
              width: "auto",
              height: "100%",
              maxHeight: "5rem",
            }}
            alt="Customer logo"
          />
        );
      } else {
        setLogo(
          <img
            src={blindspotPNG}
            style={{
              width: "auto",
              height: "100%",
              maxHeight: "5rem",
            }}
            alt="Customer logo"
          />
        );
      }
    };

    const verify = async (UUID) => {
      const { verified, customer } = await verifyCustomerAccess(
        accessToken,
        UUID
      );
      if (!verified) {
        navigate("/");
        return;
      }
      if (customer.uuid !== current_customer?.uuid) {
        await dispatch(selectCustomer(customer));
        getNotificationCount(accessToken, customer);
        getCustomerLogo(accessToken, customer);
      }
    };

    // on tab enter
    if (isVisible && accessToken && !initalLoad && user.isLoaded) {
      const customerUUID = getCustomerUUID();
      if (
        requestedCustomerUUID === null ||
        requestedCustomerUUID !== user.current_customer.uuid
      ) {
        verify(customerUUID);
      }
    }
  }, [
    navigate,
    dispatch,
    verifyCustomerAccess,
    isVisible,
    accessToken,
    user,
    setLogo,
    imageDomain,
    current_customer,
    initalLoad,
    requestedCustomerUUID,
  ]);

  if (user.archived) {
    return <ArchivedUserLanding />;
  }

  const CreateNavigation = () => {
    if (current_customer.license_type === "av_only") {
      return <AvOnlyNavigation open={open} />;
    }

    if (current_customer.license_type === "consumption_smv") {
      return <SMVNavigation open={open} />;
    }

    return <Navigation open={open} />;
  };

  // User is Archived

  if (!isAuthenticated && !isLoading) {
    if (window.location.pathname.toLowerCase() === "/register") {
      return <Register />;
    } else {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
      return <Splashscreen />;
    }
  } else if (isLoading) {
    return <Splashscreen />;
  } else if (
    !isLoading &&
    accessToken !== null &&
    user.superuser !== null &&
    user.isLoaded &&
    current_customer !== null
  ) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            minHeight: "100vh",
          }}
        >
          <AppBar position="fixed" open={open} darkmode={`${darkmode}`}>
            <Toolbar>
              {showSeleniumNav && (
                <form
                  id="selenium-input"
                  onSubmit={() =>
                    navigate(
                      `/${requestedCustomerUUID}/reporting/charts/${seleniumNav}`
                    )
                  }
                >
                  <input
                    type="text"
                    onChange={(e) => setSeleniumNav(e.target.value)}
                  />
                </form>
              )}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => handleDrawerOpen(darkmode)}
                edge="start"
                sx={{
                  color: darkmode ? "white" : "black",
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1, minHeight: "100%" }} />

              {/* Top Right Buttons */}
              <Stack direction="row" spacing={2} alignItems="center">
                {/* Customer Select */}
                {/* TODO update to has the customers passed in */}
                <CustomerSelect />

                {/* Profile Avatar */}
                <ProfileAvatar />

                {/* Alerts */}

                <Tooltip title="Alerts">
                  <IconButton size="small" onClick={handleClick}>
                    <Badge
                      badgeContent={notificationCount}
                      color="primary"
                      overlap="circular"
                    >
                      <Avatar>
                        <FaBell />
                      </Avatar>
                    </Badge>
                  </IconButton>
                </Tooltip>
                {/* Alerts */}

                <Tooltip title="Help Pages">
                  <Link to={`/${user.current_customer}/support/help-pages`}>
                    <IconButton size="small">
                      <Badge color="primary" overlap="circular">
                        <Avatar>
                          <FaInfo />
                        </Avatar>
                      </Badge>
                    </IconButton>
                  </Link>
                </Tooltip>
                {/* darkmode Toggle */}
                <IconButton onClick={() => dispatch(toggleDarkMode())}>
                  <Avatar>
                    {darkmode ? (
                      <FiSun color="gold" />
                    ) : (
                      <FaRegMoon color="black" />
                    )}
                  </Avatar>
                </IconButton>

                {/* Help Button */}
                {/* Commenting out 10/16 until we rework the help pages */}
                {/* <IconButton onClick={() => navigate("/support/help")}>
                    <Avatar>
                      <QuestionMarkIcon />
                    </Avatar>
                  </IconButton> */}
              </Stack>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={open}
            PaperProps={{
              sx: {
                backgroundColor: darkmode ? "#0A1940" : "#1B3846",
                "&::-webkit-scrollbar": { display: "none" },
              },
            }}
          >
            <DrawerHeader>
              <div style={{ marginTop: "3rem", width: "100%" }}>
                {open ? logo : null}
              </div>
              <IconButton onClick={handleDrawerClose} sx={{ color: "#BDCCD4" }}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <CreateNavigation />
            <Link
              to={`/${requestedCustomerUUID}/simulations/endpoints/download-agents`}
            >
              <Button
                sx={{
                  background:
                    "linear-gradient(to right, #2e7d32 0%, #4caf50  51%, #2e7d32  100%)",
                  color: "white",
                  margin: "0.5rem",
                  width: "90%",
                }}
                size="small"
                startIcon={<FaDownload />}
              >
                Download Agents
              </Button>
            </Link>
          </Drawer>
          <Stack
            spacing={2}
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              mb: "2rem",
              maxWidth: open ? "calc(100vw - 300px)" : "calc(100vw - 64px)",
            }}
          >
            <DrawerHeader />
            {/* Alert */}
            <OutdatedAgentAlert />
            {/*<AlertBanner />}

            {/* If the agreements are acknowledged then render children, else show the agreements page */}
            {user.eula_ack && user.privacy_ack ? (
              !user.first ||
              user.first === "" ||
              !user.last ||
              user.last === "" ? (
                <AdminAddNames user={user} setReload={setReload} />
              ) : (
                children
              )
            ) : (
              <Agreements />
            )}
          </Stack>
        </Box>
        {/* Commenting out because of the Jira Widget - Need to update for long term solution */}
        <ToastContainer theme={darkmode ? "dark" : "light"} />
        <Backdrop open={isDrawerOpen} onClick={handleClick} />
        <MuiDrawer anchor="right" open={isDrawerOpen} onClose={handleClick}>
          <Paper elevation={0} style={{ width: "300px" }}>
            <Box sx={{ padding: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 8,
                }}
              >
                <Typography variant="h6">All Notifications</Typography>
                <Tooltip title="Close or ESC">
                  <IconButton onClick={handleClick}>
                    <FaTimes />
                  </IconButton>
                </Tooltip>
              </Box>
              <Divider
                variant="fullWidth"
                style={{ height: 8, margin: "2px 0" }}
              />
              <AlertsAvatar />
            </Box>
          </Paper>
        </MuiDrawer>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: ClarityScript }}
        ></script>
      </>
    );
  } else {
    return <Splashscreen />;
  }
};

export default SiteShell;

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { FaEdit, FaRobot, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import MicrosoftConfigForm from "../../../../Components/Forms/AlertValidation/MicrosoftConfigForm";
import FormModal from "../../../../Components/Modals/FormModal";
import { DataContext } from "../../../../Context/dataContext";
import { getRequest, postRequest } from "../../../../Helpers/httpRequests";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { COLORS } from "../../../../Styles/colors";
import moment from "moment";

export const MicrosoftConfig = (props) => {
  const { data, setToolData, setReload, toolData, setOpenIntegrationModal } =
    props;
  const [showClientSecret, setShowClientSecret] = useState(false);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer,
  );
  const [microsoftRes, setMicrosoftRes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  // ----- VARIABLES -----
  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "40%",
    left: "45%",
    width: "70%",
    height: "55%",
    maxWidth: 900,
    transform: "translate(-25%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const deleteDefenderConfig = async () => {
    const res = await toast.promise(
      postRequest(
        `/react/api/${current_customer.uuid}/alertvalidation/config/delete_tool/${data.uuid}`,
        accessToken,
      ),
      {
        pending: "Deleting Defender Config",
        success: `Defender Config Deleted`,
        error: "Something went wrong!",
      },
    );
    if (res.status === 204) {
      setReload((current) => !current);
      setOpenIntegrationModal(false);
    }
  };

  // Replace every character of the client secret with a star character
  let hiddenClientSecret;
  const handleShowClientSecret = () => {
    setShowClientSecret((prev) => !prev);
  };
  let microConfig;
  hiddenClientSecret = data.tool_config?.client_secret.replace(/./g, "*");
  microConfig = {
    "Security Tool": data.name,
    "Client ID": data.tool_config.client_id,
    "Client Secret": (
      <Stack direction="row">
        <Typography>
          {showClientSecret
            ? data.tool_config.client_secret
            : hiddenClientSecret}
        </Typography>
        <IconButton onClick={handleShowClientSecret}>
          {showClientSecret ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </Stack>
    ),
    "Tenant ID": data.tool_config.tenant_id,
    Expiration: moment(data.tool_config.secret_expiration_date).format("ll"),
  };

  const testMicrosoft = useMemo(
    () => async () => {
      if (data.tool_config) {
        const res = await getRequest(
          `/react/api/${current_customer.uuid}/alertvalidation/testmicrosoft/${data.tool_config.uuid}`,
          accessToken,
        );
        if (res.status === 200) {
          setMicrosoftRes(res.data);
        } else {
          toast.error(`Error - ${res.data.statuscode}, ${res.data.message}`);
        }
        setIsLoading(false);
      }
    },
    [data, accessToken, current_customer.uuid],
  );

  useEffect(() => {
    testMicrosoft();
  }, [current_customer, testMicrosoft]);

  return (
    <>
      <Box sx={{ flex: 1, maxWidth: 900 }}>
        {/* First Half */}
        <Stack
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 3,
            flex: 1,
            height: "100%",
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">
              Microsoft Defender for Endpoint Configuration
            </Typography>
            {isLoading ? (
              <CircularProgress size={30} />
            ) : (
              Object.keys(microsoftRes).length > 0 && (
                <>
                  {microsoftRes.status === "success" ? (
                    <Chip size="medium" label="CONNECTED" color="success" />
                  ) : (
                    <Chip
                      size="medium"
                      label="FAILED TO CONNECT"
                      color="error"
                    />
                  )}
                </>
              )
            )}
          </Stack>
          <Divider />
          <Table>
            <TableBody>
              {Object.entries(microConfig).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell sx={{ borderBottom: "none", fontWeight: "bold" }}>
                    {key}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Second Half */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {/* <Typography variant="h5">Connection Information</Typography>
            <Divider style={{ marginTop: "0.5rem" }} /> */}
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                setToolData((prevData) => ({
                  ...prevData,
                  action: "edit",
                }));
                setOpenForm(true);
              }}
              title="Edit"
            >
              <FaEdit />
            </IconButton>
            <IconButton
              onClick={() => {
                setToolData((prevData) => ({
                  ...prevData,
                  action: "automation",
                }));
                setOpenForm(true);
              }}
              title="Automated Ticket Closing"
            >
              <FaRobot />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleteModal(true);
              }}
              title="Delete Defender Config"
            >
              <FaTrashAlt color={COLORS.error.main} />
            </IconButton>
          </Box>
        </Stack>
      </Box>
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove Defender Config ?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deleteDefenderConfig()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
      {/* Microsoft Add Modal*/}
      <Modal
        open={openForm}
        onClose={() => setOpenForm(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <MicrosoftConfigForm
            toolData={toolData}
            setModalOpen={setOpenForm}
            onFinish={() => setReload((current) => !current)}
          />
        </Box>
      </Modal>
    </>
  );
};

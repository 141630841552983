import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Stack,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { postRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import PropTypes from "prop-types";
import FormRowWithDetails from "../../../FormHelpers/FormRowWithDetails/FormRowWithDetails";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const MicrosoftConfigForm2 = (props) => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const [securityTools, setSecurityTools] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showClientSecret, setShowClientSecret] = useState(false);
  const { onFinish, setModalOpen, toolData } = props;
  const [formData, setFormData] = useState({
    securityTool: toolData.uuid,
    client_id: "",
    client_secret: "",
    tenant_id: "",
    uuid: "",
    status: "resolved",
    classification: "informationalExpectedActivity",
    determination: "securityTesting",
  });

  // ----- VARIABLES -----

  const STATUS_OPTIONS = [
    ["new", "New"],
    ["resolved", "Resolved"],
    ["inProgress", "In Progress"],
  ];

  const CLASSIFICATION_OPTIONS = [
    ["unknown", "Unknown"],
    ["falsePositive", "False Positive"],
    ["truePositive", "True Positive"],
    ["informationalExpectedActivity", "Informational, Expected Activity"],
  ];

  const DETERMINATION_OPTIONS = [
    ["unknown", "Unknown"],
    ["apt", "APT"],
    ["malware", "Malware"],
    ["securityPersonnel", "Security Personnel"],
    ["securityTesting", "Security Testing"],
    ["unwantedSoftware", "Unwanted Software"],
    ["other", "Other"],
    ["multiStagedAttack", "Multi-Staged Attack"],
    ["compromisedUser", "Compromised User"],
    ["phishing", "Phishing"],
    ["maliciousUserActivity", "Malicious User Activity"],
    ["clean", "Clean"],
    ["insufficientData", "Insufficient Data"],
    ["confirmedUserActivity", "Confirmed User Activity"],
    ["lineOfBusinessApplication", "Line of Business Application"],
  ];

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    if (toolData.action === "edit" || toolData.action === "automation") {
      const result = await postRequest(
        "/react/api/config/securitytoolconfigs/microsoft/edit",
        accessToken,
        formData
      );
      if (result.data.status === 200) {
        setModalOpen(false);
        if (onFinish) {
          onFinish();
        }
      } else {
        setErrorMessage(`${result.data.status} - ${result.data.message}`);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000); // wait 5 seconds before clearing the error message
      }
    }

    if (toolData.action === "add") {
      const result = await postRequest(
        "/react/api/config/securitytoolconfigs/microsoft/add",
        accessToken,
        formData
      );
      if (result.data.status === 200) {
        setModalOpen(false);
        if (onFinish) {
          onFinish();
        }
      } else {
        setErrorMessage(`${result.data.status} - ${result.data.message}`);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000); // wait 5 seconds before clearing the error message
      }
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const setForm = async () => {
      setSecurityTools(toolData.securityOptions);
      if (toolData.action === "edit" || toolData.action === "automation") {
        // Update to correct data
        setFormData({
          ...formData,
          securityTool: toolData.tool.uuid,
          client_id: toolData.tool.tool_config.client_id,
          client_secret: toolData.tool.tool_config.client_secret,
          tenant_id: toolData.tool.tool_config.tenant_id,
          uuid: toolData.tool.tool_config.uuid,
          status: toolData.tool.tool_config.status,
          classification: toolData.tool.tool_config.classification,
          determination: toolData.tool.tool_config.determination,
        });
      }
    };
    setForm();
  }, [accessToken, toolData, formData]);

  let form_jsx = <></>;

  if (toolData.action === "add" || toolData.action === "edit") {
    form_jsx = (
      <div>
        <Typography variant="h5">Windows Defender Configuration</Typography>
        <Divider style={{ margin: "1rem 0" }} />
        <Stack spacing={2}>
          {/* Security Tool */}
          <FormControl>
            <InputLabel id="alert-security-tool">Security Tool</InputLabel>
            <Select
              labelId="alert-security-tool"
              id="alert-security-tool-select"
              value={formData.securityTool}
              disabled={toolData.uuid}
              label="Security Tool"
              onChange={(e) =>
                setFormData({ ...formData, securityTool: e.target.value })
              }
            >
              {securityTools
                ? securityTools.map((tool) => (
                  <MenuItem key={tool.uuid} value={tool.uuid}>
                    {tool.name}
                  </MenuItem>
                ))
                : null}
            </Select>
          </FormControl>
          {/* Client ID */}
          <TextField
            required
            id="tool-client-id"
            label="Client ID"
            value={formData.client_id}
            onChange={(e) =>
              setFormData({ ...formData, client_id: e.target.value })
            }
          />
          {/* Client secret */}
          <TextField
            required
            type={showClientSecret ? "text" : "password"}
            id="tool-client-secret"
            label="Client Secret"
            value={formData.client_secret}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowClientSecret(!showClientSecret)}
                  >
                    {showClientSecret ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) =>
              setFormData({ ...formData, client_secret: e.target.value })
            }
          />

          {/* Tenant ID */}
          <TextField
            required
            id="tool-tenant-id"
            label="Tenant ID"
            value={formData.tenant_id}
            onChange={(e) =>
              setFormData({ ...formData, tenant_id: e.target.value })
            }
          />
        </Stack>
      </div>
    );
  } else if (toolData.action === "automation") {
    form_jsx = (
      <>
        <Typography variant="h5">
          Windows Defender Automated Ticket Closing
        </Typography>
        <Divider style={{ margin: "1rem 0" }} />
        <Stack spacing={2}>
          <FormRowWithDetails
            type="select"
            options={STATUS_OPTIONS}
            label="status"
            formData={formData}
            setFormData={setFormData}
            formKey="status"
            description="The status on Defender to be set"
            required
          />
          <FormRowWithDetails
            type="select"
            options={CLASSIFICATION_OPTIONS}
            label="classification"
            formData={formData}
            setFormData={setFormData}
            formKey="classification"
            description="The classification on Defender to be set"
            required
          />
          <FormRowWithDetails
            type="select"
            options={DETERMINATION_OPTIONS}
            label="determination"
            formData={formData}
            setFormData={setFormData}
            formKey="determination"
            description="The determination on Defender to be set"
            required
          />
        </Stack>
      </>
    );
  }

  return (
    <Stack spacing={2}>
      {form_jsx}
      <Button
        onClick={handleSubmit}
        variant="contained"
        color={errorMessage ? "error" : "primary"}
      >
        {errorMessage ? errorMessage : "Submit"}
      </Button>
    </Stack>
  );
};

MicrosoftConfigForm2.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
};

export default MicrosoftConfigForm2;

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useGetRequest from "../../../../Helpers/httpRequests/useGetRequest";
import DataGridWithStyles from "../../../../Components/DataGrid/DataGridWithStyles/DataGridWithStyles";
import { Paper, Typography, CircularProgress } from "@mui/material";
import { AvOverviewContext } from "../Provider/Context";
import { encryptId } from "../../../../Helpers/functions/uni_funcs";
import { useAppSelector } from "../../../../Redux/app/hooks";

const MTTD = () => {
  const { startDate, endDate, setAvgMTTD } = useContext(AvOverviewContext);
  const current_customer = useAppSelector((state) => state.customer);
  // response with start and end params in url
  const response = useGetRequest(
    `/react/api/${
      current_customer.uuid
    }/alertvalidation/overview/mttd?start=${startDate.toISOString()}&end=${endDate.toISOString()}`
  );

  const [dataTableRows, setDataTableRows] = useState([]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
  };

  const columns = [
    { field: "alertTitle", headerName: "Alert Title", flex: 1 },
    { field: "product", headerName: "Product", flex: 1 },
    {
      field: "meantimetodetect",
      headerName: "Mean Time to Detect",
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return <CircularProgress />;
        }
        return formatTime(params.value);
      },
    },
    {
      field: "longest_time",
      headerName: "Longest Detection Time",
      flex: 1,
      renderCell: (params) => {
        // return formatTime(params.value);
        let time = formatTime(params.value);
        return (
          <Link
            to={`/${current_customer.uuid}/alertvalidation/alerts/details/${encryptId(
              params.row.longest_time_exercise_id
            )}`}
          >
            {time}
          </Link>
        );
      },
    },
    {
      field: "shortest_time",
      headerName: "Shortest Detection Time",
      flex: 1,
      renderCell: (params) => {
        return formatTime(params.value);
      },
    },
  ];

  useEffect(() => {
    const populateDataGrid = (data) => {
      let totalSeconds = 0;
      let totalFindings = 0;
      const rows = data.map((item) => {
        let id = item.title + item.product;
        totalSeconds += item.total_time;
        totalFindings += item.total_findings;
        return {
          id,
          alertTitle: item.title,
          meantimetodetect: item.meantimetodetect,
          product: item.product,
          longest_time: item.longest_time,
          shortest_time: item.shortest_time,
          longest_time_exercise_id: item.longest_time_exercise_id,
        };
      });
      setDataTableRows(rows);
      setAvgMTTD(totalSeconds / totalFindings);
    };
    if (response.status === 200) {
      populateDataGrid(response.data.mttd);
    }
  }, [response, setAvgMTTD, startDate, endDate]);

  return (
    <Paper sx={{ p: 3, width: "50%" }}>
      <Typography variant="h5">Mean Time to Detect</Typography>
      <DataGridWithStyles
        name="mttd-grid"
        title="Mean Time to Detect"
        columns={columns}
        rows={dataTableRows}
        autoHeight
        disableSelectionOnClick
        paper
      />
    </Paper>
  );
};
export default MTTD;

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";

const CrowdstrikeConfig2 = (props) => {
  const { data, handleEditOrAdd, setCrowdstrikeConfig, setHasCrowdstrike } =
    props;
  const [showClientScret, setShowClientSecret] = useState(false);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.user.current_customer
  );
  const [isLoading, setIsLoading] = useState(true);
  const [crowdstrikeRes, setCrowdstrikeRes] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteCrowdstrikeConfig = async () => {
    toast.info("Attempting to delete crowdstrike config");
    const res = await postRequest(
      `/react/api/${current_customer.uuid}/config/delete_tool/${data.uuid}`,
      accessToken
    );
    if (res.status === 204) {
      toast.success("Crowdstrike config successfully deleted");
      setCrowdstrikeConfig(false);
      setHasCrowdstrike(false);
    }
  };

  // Replace every character of the client secret with a star character
  let hiddenClientSecret;
  let config;
  const handleShowClientSecret = () => {
    setShowClientSecret((prev) => !prev);
  };
  if (data?.tool_config != null) {
    hiddenClientSecret = data.tool_config.client_secret.replace(/./g, "*");
    config = {
      "Security Tool:": data.name,
      "Base Url:": data.tool_config.base_url,
      "Falcon Url:": data.tool_config.falcon_base_url,
      "Client ID:": data.tool_config.client_id,
      "Client Secret:": (
        <Stack direction="row">
          <Typography>
            {showClientScret
              ? data.tool_config.client_secret
              : hiddenClientSecret}
          </Typography>
          <IconButton onClick={handleShowClientSecret}>
            {showClientScret ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Stack>
      ),
    };
  }

  const testCrowdstrike = useMemo(
    () => async () => {
      if (data?.tool_config.uuid) {
        const res = await getRequest(
          `/react/api/${current_customer.uuid}/testcrowdstrike/${data.tool_config.uuid}`,
          accessToken
        );
        if (res.status === 200) {
          setCrowdstrikeRes(res.data);
        } else {
          toast.error(`Error - ${res.data.statuscode}, ${res.data.message}`);
        }
        setIsLoading(false);
      }
    },
    [data, accessToken, current_customer]
  );

  useEffect(() => {
    testCrowdstrike();
  }, [current_customer, testCrowdstrike]);

  return (
    <>
      <Paper sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        {/* First Half */}
        <Stack
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 3,
            flex: 1,
            height: "100%",
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">Crowdstrike Configuration</Typography>
            {isLoading ? (
              <CircularProgress size={30} />
            ) : (
              Object.keys(crowdstrikeRes).length > 0 && (
                <>
                  {crowdstrikeRes.status === "success" ? (
                    <Chip size="medium" label="CONNECTED" color="success" />
                  ) : (
                    <Chip
                      size="medium"
                      label="FAILED TO CONNECT"
                      color="error"
                    />
                  )}
                </>
              )
            )}
          </Stack>
          <Divider />
          <Table>
            <TableBody>
              {Object.entries(config).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell sx={{ borderBottom: "none", fontWeight: "bold" }}>
                    {key}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Second Half */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography variant="h5">Connection Information</Typography>
            <Divider style={{ marginTop: "0.5rem" }} />
          </Box>
          <Box
            sx={{
              width: "100%",
              textAlign: "right",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => handleEditOrAdd("crowdstrike", "edit", data)}
              title="Edit"
            >
              <FaEdit />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleteModal(true);
              }}
              title="Delete CrowdStrike Config"
            >
              <FaTrashAlt color={COLORS.error.main} />
            </IconButton>
          </Box>
        </Stack>
      </Paper>
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove CrowdStrike Config ?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button
              onClick={() => deleteCrowdstrikeConfig()}
              variant="contained"
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
    </>
  );
};

export default CrowdstrikeConfig2;

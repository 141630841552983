import {
  Alert,
  Breadcrumbs,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import TextButton from "../../../Components/Buttons/TextButton";
import CrowdstrikeConfigForm2 from "../../../Components/Forms/AlertValidation/CrowdstrikeConfigForm/CrowdstrikeConfigForm2";
import MicrosoftConfigForm2 from "../../../Components/Forms/AlertValidation/MicrosoftConfigForm/MicrosoftConfigForm2";
import SentinelConfigForm2 from "../../../Components/Forms/AlertValidation/SentinelConfigForm/SentinelConfigForm2";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import CrowdstrikeConfig2 from "./CrowdstrikeConfig2";
import { LogChain } from "./LogChain/LogChain";
import MicrosoftConfig2 from "./MicrosoftConfig2";
import SentinelConfig2 from "./SentinelConfig2";

const AV_Config = () => {
  // ----- STATES && CONTEXT -----
  const [openMicrosoft, setOpenMicrosoft] = useState(false);
  const [openSentinel, setOpenSentinel] = useState(false);
  const [openCrowdstrike, setOpenCrowdstrike] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [hasDefender, setHasDefender] = useState(true);
  const [hasSentinel, setHasSentinel] = useState(true);
  const [hasCrowdstrike, setHasCrowdstrike] = useState(true);
  const [defenderConfig, setDefenderConfig] = useState(false);
  const [sentinelConfig, setSentinelConfig] = useState(false);
  const [crowdstrikeConfig, setCrowdstrikeConfig] = useState(false);
  const [selectToolModal, setSelectToolModal] = useState(false);
  const [toolData, setToolData] = useState({
    action: "",
    securityOptions: [],
    tool: {},
    uuid: null,
  });
  const [data, setData] = useState({
    securityOptions: [],
  });
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  // ----- VARIABLES -----
  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "40%",
    left: "45%",
    width: "70%",
    maxHeight: "70%",
    maxWidth: 900,
    transform: "translate(-25%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  // ----- FUNCTIONS -----

  const handleEditOrAdd = (itemType, action, tool) => {
    setToolData({
      action: action,
      securityOptions: data.securityOptions,
      tool: tool,
      uuid: "",
    });
    if (itemType === "microsoft") {
      const uuid = data.securityOptions.find(
        (tool) => tool.product === "Defender"
      ).uuid;
      setToolData((prevData) => ({
        ...prevData,
        uuid: uuid,
      }));
      setOpenMicrosoft(true);
    } else if (itemType === "sentinel") {
      const uuid = data.securityOptions.find(
        (tool) => tool.product === "Sentinel"
      ).uuid;
      setToolData((prevData) => ({
        ...prevData,
        uuid: uuid,
      }));
      setOpenSentinel(true);
    } else if (itemType === "crowdstrike") {
      const uuid = data.securityOptions.find(
        (tool) => tool.product === "Crowdstrike"
      ).uuid;
      setToolData((prevData) => ({
        ...prevData,
        uuid: uuid,
      }));
      setOpenCrowdstrike(true);
    }
  };

  const handleToolAddSelection = (e) => {
    setSelectToolModal(false);
    if (e.target.value === "Defender") {
      handleEditOrAdd("microsoft", "add");
    } else if (e.target.value === "Sentinel") {
      handleEditOrAdd("sentinel", "add");
    } else if (e.target.value === "Crowdstrike") {
      handleEditOrAdd("crowdstrike", "add");
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const getConfig = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/alertvalidation/config`,
        accessToken
      );
      if (res.status === 200) {
        const securityOptions = res.data.securityOptions;
        const hasDefender = securityOptions.some(
          (tool) => tool.product === "Defender"
        );
        const hasSentinel = securityOptions.some(
          (tool) => tool.product === "Sentinel"
        );
        const hasCrowdstrike = securityOptions.some(
          (tool) => tool.product === "Crowdstrike"
        );
        const hasDefenderConfig = securityOptions.some(
          (tool) => tool.product === "Defender" && tool.tool_config !== null
        );
        const hasSentinelConfig = securityOptions.some(
          (tool) => tool.product === "Sentinel" && tool.tool_config !== null
        );
        const hasCrowdstrikeConfig = securityOptions.some(
          (tool) => tool.product === "Crowdstrike" && tool.tool_config !== null
        );
        setData((prevData) => ({
          ...prevData,
          securityOptions: securityOptions,
        }));
        setDefenderConfig(hasDefenderConfig);
        setHasDefender(hasDefender);
        setSentinelConfig(hasSentinelConfig);
        setHasSentinel(hasSentinel);
        setCrowdstrikeConfig(hasCrowdstrikeConfig);
        setHasCrowdstrike(hasCrowdstrike);
      }
    };

    getConfig();
  }, [current_customer, accessToken, reloadPage]);

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Alert Validation</Typography>
        <Typography color="text.primary">Config</Typography>
      </Breadcrumbs>
      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography fontWeight={"bold"} variant="h4">
          Security Tool Integrations
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          {(hasSentinel && !sentinelConfig) ||
            (hasDefender && !defenderConfig) ||
            (hasCrowdstrike && !crowdstrikeConfig) ? (
            <TextButton
              tooltip="Add Config"
              onClick={() => setSelectToolModal(true)}
              icon={FaPlus}
            />
          ) : null}
        </Box>
      </Box>
      {!defenderConfig && !sentinelConfig && !crowdstrikeConfig && (
        <Alert severity="warning">No Security Tool Config has been added</Alert>
      )}
      {!hasSentinel && !hasDefender && !hasCrowdstrike && (
        <Alert severity="info">
          Security Tools has not beed added— please add it from the{" "}
          <Link to={`/${current_customer.uuid}/config/securitytools`}>
            <b>Security Tool Page</b>
          </Link>
        </Alert>
      )}
      <Stack direction="row" spacing={2}>
        {defenderConfig && (
          <MicrosoftConfig2
            data={data.securityOptions.find(
              (tool) => tool.product === "Defender"
            )}
            handleEditOrAdd={handleEditOrAdd}
            setDefenderConfig={setDefenderConfig}
            setHasDefender={setHasDefender}
          />
        )}
        {sentinelConfig && (
          <SentinelConfig2
            data={data.securityOptions.find(
              (tool) => tool.product === "Sentinel"
            )}
            handleEditOrAdd={handleEditOrAdd}
            setSentinelConfig={setSentinelConfig}
            setHasSentinel={setHasSentinel}
          />
        )}
        {crowdstrikeConfig && (
          <CrowdstrikeConfig2
            data={data.securityOptions.find(
              (tool) => tool.product === "Crowdstrike"
            )}
            handleEditOrAdd={handleEditOrAdd}
            setCrowdstrikeConfig={setCrowdstrikeConfig}
            setHasCrowdstrike={setHasCrowdstrike}
          />
        )}
      </Stack>

      <Box>
        <LogChain />
      </Box>
      {/* Microsoft */}
      <Modal
        open={openMicrosoft}
        onClose={() => setOpenMicrosoft(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <MicrosoftConfigForm2
            toolData={toolData}
            setModalOpen={setOpenMicrosoft}
            onFinish={() => setReloadPage((current) => !current)}
          />
        </Box>
      </Modal>

      {/* Add Sentinel */}
      <Modal
        open={openSentinel}
        onClose={() => setOpenSentinel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <SentinelConfigForm2
            toolData={toolData}
            setModalOpen={setOpenSentinel}
            onFinish={() => setReloadPage((current) => !current)}
          />
        </Box>
      </Modal>

      {/* Add Crowdstrike */}
      <Modal
        open={openCrowdstrike}
        onClose={() => setOpenCrowdstrike(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <CrowdstrikeConfigForm2
            toolData={toolData}
            setModalOpen={setOpenCrowdstrike}
            onFinish={() => setReloadPage((current) => !current)}
          />
        </Box>
      </Modal>

      {/* Select Tool to Add Modal */}
      <Modal
        open={selectToolModal}
        onClose={() => setSelectToolModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Select Tool to Add
          </Typography>
          <FormControl fullWidth>
            <InputLabel>Select a Tool</InputLabel>
            <Select onChange={handleToolAddSelection}>
              {hasDefender && !defenderConfig && (
                <MenuItem value="Defender">Defender</MenuItem>
              )}
              {hasSentinel && !sentinelConfig && (
                <MenuItem value="Sentinel">Sentinel</MenuItem>
              )}
              {hasCrowdstrike && !crowdstrikeConfig && (
                <MenuItem value="Crowdstrike">Crowdstrike</MenuItem>
              )}
              {/* Add more options for other tools if needed */}
            </Select>
          </FormControl>
        </Box>
      </Modal>
    </Stack>
  );
};

export default AV_Config;

import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";
import { getRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import { Box } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CampaignDataTable from "../../../../Components/Reporting/CampaignDataTable";
import ActivityTimeline from "../../../Reports/ReportsCampaignDetails/ActivityTimeline";
import { useAppSelector } from "../../../../Redux/app/hooks";

export const CampaignList = (props) => {
  const { uuid, recommendations } = props;
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.customer
  );

  const [campaigns, setCampagins] = useState([]);
  const [executionThresholds, setExecutionThresholds] = useState({
    windows_binary: "logged",
    windows_binary_inmem: "logged",
    windows_dll: "logged",
    windows_pi_remote: "logged",
    windows_pi_local: "logged",
    linux_binary: "logged",
  });
  const [campaignToReload, setCampaignToReload] = useState(-2);

  const createCampaignTable = useCallback(
    (campaignData, idx) => {
      const execution = campaignData.cached_findings[0].campaign.executionFullName;
      const executionThreshold = executionThresholds[execution];
      return (
        <Box>
          <div>
            <ActivityTimeline timeline={campaignData.timeline} index={idx} />
          </div>

          <CampaignDataTable
            findings={campaignData.cached_findings}
            updateChart={() => {
              setCampaignToReload(idx + 1);
            }}
            executionThreshold={executionThreshold}
            recommendations={recommendations}
          />
        </Box>
      )
    },
    [executionThresholds, recommendations]
  );

  useEffect(() => {
    const getFindings = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/projectreport/${uuid}/findings`,
        accessToken
      );
      if (res.status === 200) {
        setCampagins(res.data.campaigns);
      }
    };

    const getExecutionThresholds = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/execution-thresholds`,
        accessToken
      );
      if (res.status === 200) {
        setExecutionThresholds(res.data.thresholds);
      }
    };

    if (campaignToReload !== -1 || campaignToReload === -2) {
      getFindings();
      getExecutionThresholds();
      setCampaignToReload(-1);
    }
  }, [accessToken, current_customer, campaignToReload, uuid]);

  return (
    <Paper sx={{ padding: "1rem" }}>
      <Typography variant="h5">Campaigns</Typography>
      {campaigns.length > 0 ? (
        campaigns.map((campaign, index) => {
          return (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`campaign${campaign.cached_findings[0].campaign.uuid}-accordion`}
                id={`campaign${campaign.cached_findings[0].campaign.uuid}-accordion`}
              >
                <Typography
                  variant="h5"
                  sx={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    padding: "1rem",
                  }}
                >
                  {campaign.cached_findings[0].campaign.name}
                </Typography>
              </AccordionSummary>
              {createCampaignTable(campaign, index)}
            </Accordion>
          );
        })
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
};

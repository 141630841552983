import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import commandlinePNG from "../../../Images/icons/command-line.png";
import downloadSolidSVG from "../../../Images/icons/download-solid.svg";
import uploadSolidSVG from "../../../Images/icons/upload-solid.svg";
import keyboardSolidSVG from "../../../Images/icons/keyboard-solid.svg";
import powershellSVG from "../../../Images/icons/powershell.svg";
import vectorLocKLogo from "../../../Images/icons/vector-lock-logo.png";
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

const ActivityTimeline = (props) => {
  const idx = props.index;
  // ----- Functions -----

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const populateChart = async (timelinedata) => {
      am4core.ready(function () {
        am4core.useTheme(am4themes_animated);
        am4core.options.autoDispose = true;
        let chart = am4core.create(
          `chartdiv-${idx}`,
          am4plugins_timeline.SerpentineChart
        );
        chart.curveContainer.padding(100);
        chart.levelCount = 3;
        chart.maskBullets = false;
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.items = [
          {
            label: "...",
            menu: [
              { type: "png", label: "PNG" },
              { type: "jpg", label: "JPG" },
              { type: "svg", label: "SVG" },
              { type: "pdf", label: "PDF" },
            ],
          },
        ];
        chart.exporting.filePrefix = "blindspot";
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm:ss";
        chart.dateFormatter.dateFormat = "mm";
        chart.data = timelinedata;
        chart.fontSize = 12;
        chart.tooltipContainer.fontSize = 1;
        chart.tooltip.disabled = true;

        chart.exporting.menu.items = [
          {
            label: "...",
            menu: [
              { type: "png", label: "PNG" },
              { type: "jpg", label: "JPG" },
              { type: "svg", label: "SVG" },
              { type: "pdf", label: "PDF" },
            ],
          },
        ];

        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.labels.template.paddingRight = 25;
        categoryAxis.renderer.minGridDistance = 10;

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 10;
        dateAxis.renderer.tooltipLocation = 0;
        dateAxis.renderer.line.strokeDasharray = "1,4";
        dateAxis.renderer.line.strokeOpacity = 0.5;
        dateAxis.tooltip.disabled = true;
        dateAxis.startLocation = 0;
        dateAxis.endLocation = 0;
        // Disable labels and grid
        dateAxis.renderer.labels.template.disabled = true;
        dateAxis.renderer.grid.template.disabled = true;

        let labelTemplate = dateAxis.renderer.labels.template;
        labelTemplate.verticalCenter = "middle";
        labelTemplate.fillOpacity = 0.4;
        labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor(
          "background"
        );
        labelTemplate.background.fillOpacity = 1;
        labelTemplate.padding(7, 7, 7, 7);

        let series = chart.series.push(
          new am4plugins_timeline.CurveColumnSeries()
        );
        series.columns.template.height = am4core.percent(5); // Set fixed height for columns
        series.columns.template.marginTop = 10;
        series.columns.template.marginBottom = 40; // Add margin bottom per line curve

        series.dataFields.openDateX = "start";
        series.dataFields.dateX = "end";
        series.dataFields.categoryY = "category";
        series.baseAxis = categoryAxis;
        series.columns.template.stroke = am4core.color("#0E0807");
        series.columns.template.fill = am4core.color("#0E0807");
        series.columns.template.fillOpacity = 0.6;

        let imageBullet1 = series.bullets.push(
          new am4plugins_bullets.PinBullet()
        );
        imageBullet1.locationX = 1;
        imageBullet1.propertyFields.stroke = "color";
        imageBullet1.background.propertyFields.fill = "color";
        imageBullet1.image = new am4core.Image();
        imageBullet1.image.propertyFields.href = "icon";
        imageBullet1.image.scale = 0.5;
        imageBullet1.circle.radius = am4core.percent(100);
        imageBullet1.dy = -5;

        let textBullet = series.bullets.push(new am4charts.LabelBullet());
        textBullet.label.propertyFields.text = "text";
        textBullet.disabled = true;
        textBullet.propertyFields.disabled = "textDisabled";
        textBullet.label.strokeOpacity = 0;
        textBullet.locationX = 1;
        textBullet.dy = -100;
        textBullet.label.textAlign = "middle";

        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.align = "center";
        chart.scrollbarX.width = am4core.percent(75);
        chart.scrollbarX.opacity = 0.5;

        let cursor = new am4plugins_timeline.CurveCursor();
        chart.cursor = cursor;
        cursor.xAxis = dateAxis;
        cursor.yAxis = categoryAxis;
        cursor.lineY.disabled = true;
        cursor.lineX.strokeDasharray = "1,4";
        cursor.lineX.strokeOpacity = 1;
        cursor.tooltip.disabled = true;

        categoryAxis.cursorTooltipEnabled = false;
        cursor.lineHeight = 0.2; // Adjust this value to change the curve height

        let label = chart.createChild(am4core.Label);
        label.isMeasured = false;
        label.y = am4core.percent(40);
        label.x = am4core.percent(50);
        label.horizontalCenter = "middle";
        label.fontSize = 12;

        let legend = new am4charts.Legend();
        legend.parent = chart.chartContainer;
        legend.itemContainers.template.togglable = false;
        legend.marginTop = 10;

        series.events.on("ready", function (ev) {
          let legenddata = [];
          let names = [];
          series.columns.each(function (column) {
            let data_name = column.dataItem.dataContext.action;
            if (!names.includes(data_name)) {
              let legend_item = {
                name: data_name,
                fill: column.dataItem.dataContext.color,
                count: 1,
              };
              legenddata.push(legend_item);
              names.push(data_name);
            } else {
              legenddata.forEach(function (o) {
                if (o.name === data_name) {
                  o.count += 1;
                }
              });
            }
          });
          legenddata.forEach(function (o) {
            o.name = o.name + " - " + o.count;
          });
          legend.data = legenddata;

          let title = chart.titles.create();
          title.text = "Simulation Report";
          title.fontSize = 25;
          title.marginBottom = 30;
        });
      });
    }

    const populateTimeLineChart = async (timelines) => {
      const interval = 1000; // Time interval in milliseconds
      let currentTime = 0;
      const timelinedata = timelines.map((timed) => {
        let icon = "";
        let name = "";
        switch (timed.icon) {
          case "run":
            icon = commandlinePNG;
            break;
          case "downlaod":
            icon = downloadSolidSVG;
            break;
          case "upload":
            icon = uploadSolidSVG;
            break;
          case "keylogger":
            icon = keyboardSolidSVG;
            break;
          case "powershell":
            icon = powershellSVG;
            break;
          default:
            icon = vectorLocKLogo;
        }
        switch (timed.name) {
          case "run":
            name = "CLI";
            break;
          case "upsh":
            name = "Powershell";
            break;
          case "downloader":
            name = "File Download";
            break;
          case "uploader":
            name = "File Exfiltration";
            break;
          case "sysinfo":
            name = "System Info - API";
            break;
          case "crypt":
            name = "Encrypting Data";
            break;
          case "arp":
            name = "ARP - API";
            break;
          case "file":
            name = "Creating Sample Data";
            break;
          case "services":
            name = "Services - API";
            break;
          case "search":
            name = "File Search - API";
            break;
          case "processes":
            name = "Processes - API";
            break;
          case "reg":
            name = "Registry Access";
            break;
          case "mimikatz":
            name = "Mimikatz";
            break;
          default:
            name = timed.name;
        }

        // Set the current time as the start and increment it by the interval
        const start = currentTime;
        currentTime += interval;
        return {
          text: name,
          start,
          end: currentTime,
          color: timed.color,
          category: "",
          action: timed.action,
          textDisabled: false,
          icon: icon,
        };
      });
      await populateChart(timelinedata);
    };
    populateTimeLineChart(props.timeline);
  }, [props.timeline, idx]);

  // Memoize the chart container to prevent unnecessary re-renders
  const chartContainer = useMemo(() => {
    return (
      <div id={`chartdiv-${idx}`} style={{ width: "100%", height: "700px" }} />
    );
  }, [idx]);

  return chartContainer;
};

ActivityTimeline.propTypes = {
  timeline: PropTypes.array.isRequired,
};

export default ActivityTimeline;

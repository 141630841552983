import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { FaEdit, FaRobot, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";

const MicrosoftConfig2 = (props) => {
  const { data, handleEditOrAdd, setDefenderConfig, setHasDefender } = props;
  const [showClientSecret, setShowClientSecret] = useState(false);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector(
    (state) => state.user.current_customer
  );
  // const [microsoftRes, setMicrosoftRes] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteDefenderConfig = async () => {
    const res = await toast.promise(
      postRequest(
        `/react/api/${current_customer.uuid}/config/delete_tool/${data.uuid}`,
        accessToken
      ),
      {
        pending: "Deleting Defender Config",
        success: `Defender Config Deleted`,
        error: "Something went wrong!",
      }
    );
    if (res.status === 204) {
      setHasDefender(false);
      setDefenderConfig(false);
    }
  };

  // Replace every character of the client secret with a star character
  let hiddenClientSecret;
  const handleShowClientSecret = () => {
    setShowClientSecret((prev) => !prev);
  };
  let microConfig;
  hiddenClientSecret = data.tool_config?.client_secret.replace(/./g, "*");
  microConfig = {
    "Security Tool": data.name,
    "Client ID": data.tool_config.client_id,
    "Client Secret": (
      <Stack direction="row">
        <Typography>
          {showClientSecret
            ? data.tool_config.client_secret
            : hiddenClientSecret}
        </Typography>
        <IconButton onClick={handleShowClientSecret}>
          {showClientSecret ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </Stack>
    ),
    "Tenant ID": data.tool_config.tenant_id,
  };

  const testMicrosoft = useMemo(
    () => async () => {
      if (data.tool_config) {
        const res = await getRequest(
          `/react/api/${current_customer.uuid}/testmicrosoft/${data.tool_config.uuid}`,
          accessToken
        );
        if (res.status === 200) {
          // setMicrosoftRes(res.data);
          console.log(res)
          
        } else {
          toast.error(`Error - ${res.data.statuscode}, ${res.data.message}`);
        }
        // setIsLoading(false);
      }
    },
    [data, accessToken, current_customer]
  );

  useEffect(() => {
    testMicrosoft();
  }, [current_customer, testMicrosoft]);

  return (
    <>
      <Paper sx={{ flex: 1, maxWidth: 900 }}>
        {/* First Half */}
        <Stack
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 3,
            flex: 1,
            height: "100%",
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">
              Microsoft Defender for Endpoint Configuration
            </Typography>
            {/*{isLoading ? (*/}
            {/*  <CircularProgress size={30} />*/}
            {/*) : (*/}
            {/*  Object.keys(microsoftRes).length > 0 && (*/}
            {/*    <>*/}
            {/*      {microsoftRes.status === "success" ? (*/}
            {/*        <Chip size="medium" label="CONNECTED" color="success" />*/}
            {/*      ) : (*/}
            {/*        <Chip*/}
            {/*          size="medium"*/}
            {/*          label="FAILED TO CONNECT"*/}
            {/*          color="error"*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    </>*/}
            {/*  )*/}
            {/*)}*/}
          </Stack>
          <Divider />
          <Table>
            <TableBody>
              {Object.entries(microConfig).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell sx={{ borderBottom: "none", fontWeight: "bold" }}>
                    {key}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Second Half */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography variant="h5">Connection Information</Typography>
            <Divider style={{ marginTop: "0.5rem" }} />
          </Box>
          <Box>
            <IconButton
              onClick={() => handleEditOrAdd("microsoft", "edit", data)}
              title="Edit"
            >
              <FaEdit />
            </IconButton>
            <IconButton
              onClick={() => handleEditOrAdd("microsoft", "automation", data)}
              title="Automated Ticket Closing"
            >
              <FaRobot />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleteModal(true);
              }}
              title="Delete Defender Config"
            >
              <FaTrashAlt color={COLORS.error.main} />
            </IconButton>
          </Box>
        </Stack>
      </Paper>
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove Defender Config ?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button onClick={() => deleteDefenderConfig()} variant="contained">
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
    </>
  );
};

export default MicrosoftConfig2

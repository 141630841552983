import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Modal,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";
import SentinelOneConfigForm from "../../../Components/Forms/AlertValidation/SentinelOneConfigForm";

const SentinelOneConfig = (props) => {
  const { data, setToolData, setReload, toolData, setOpenIntegrationModal } =
    props;
  const [showApiToken, setShowApiToken] = useState(false);
  const { accessToken } = useContext(DataContext);
  const current_customer = useAppSelector((state) => state.customer);
  const [isLoading, setIsLoading] = useState(true);
  const [sentinelOneRes, setSentinelOneRes] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  // ----- VARIABLES -----
  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "40%",
    left: "45%",
    width: "70%",
    height: "55%",
    maxWidth: 900,
    transform: "translate(-25%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const deleteSentinelOneConfig = async () => {
    const res = await toast.promise(
      postRequest(
        `/react/api/${current_customer.uuid}/alertvalidation/config/delete_tool/${data.uuid}`,
        accessToken
      ),
      {
        pending: "Deleting Sentinel Config",
        success: `Sentinel Config Deleted`,
        error: "Something went wrong!",
      }
    );
    if (res.status === 204) {
      setReload((current) => !current);
      setOpenIntegrationModal(false);
    }
  };

  // Replace every character of the client secret with a star character
  let hiddenId;
  let config;
  if (data.tool_config != null) {
    hiddenId = data.tool_config.apiToken.replace(/./g, "*");
    config = {
      "Security Tool:": data.name,
      "Base_url:": data.tool_config.base_url,
      "Api Token:": (
        <Stack direction="row" alignItems="center">
          <Typography sx={{ wordBreak: "break-all" }}>
            {showApiToken ? data.tool_config.apiToken : hiddenId}
          </Typography>
          <IconButton onClick={() => setShowApiToken((current) => !current)}>
            {showApiToken ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Stack>
      ),
    };
  }

  const testSentinelOne = useMemo(
    () => async () => {
      if (data.tool_config?.uuid) {
        const res = await getRequest(
          `/react/api/${current_customer.uuid}/alertvalidation/testsentinelOne/${data.tool_config.uuid}`,
          accessToken
        );
        if (res.status === 200) {
          setSentinelOneRes(res.data);
        } else {
          setSentinelOneRes(res.data);
          toast.error(`Error  ${res.data.status_code}, ${res.data.reason}`);
        }
        setIsLoading(false);
      }
    },
    [data, accessToken, current_customer.uuid]
  );

  useEffect(() => {
    testSentinelOne();
  }, [current_customer, testSentinelOne]);

  return (
    <>
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        {/* First Half */}
        <Stack
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 3,
            flex: 1,
            height: "100%",
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">SentinelOne Configuration</Typography>
            {isLoading ? (
              <CircularProgress size={30} />
            ) : (
              Object.keys(sentinelOneRes).length > 0 && (
                <>
                  {sentinelOneRes.status_code === 200 ? (
                    <Chip size="medium" label="CONNECTED" color="success" />
                  ) : (
                    <Chip
                      size="medium"
                      label="FAILED TO CONNECT"
                      color="error"
                    />
                  )}
                </>
              )
            )}
          </Stack>
          <Divider />
          <Table>
            <TableBody>
              {Object.entries(config).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell sx={{ borderBottom: "none", fontWeight: "bold" }}>
                    {key}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Second Half */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          ></Box>
          <Box
            sx={{
              width: "100%",
              textAlign: "right",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                setToolData((prevData) => ({
                  ...prevData,
                  action: "edit",
                }));
                setOpenForm(true);
              }}
              title="Edit"
            >
              <FaEdit />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleteModal(true);
              }}
              title="Delete Sentinel One Config"
            >
              <FaTrashAlt color={COLORS.error.main} />
            </IconButton>
          </Box>
        </Stack>
      </Box>
      {/* Delete Modal */}
      <FormModal open={deleteModal} setOpen={setDeleteModal}>
        <Box component="form">
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            Are you sure you want to remove Sentinel Config ?
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button
              onClick={() => deleteSentinelOneConfig()}
              variant="contained"
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </FormModal>
      {/* Sentinel Add Modal*/}
      <Modal
        open={openForm}
        onClose={() => setOpenForm(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <SentinelOneConfigForm
            toolData={toolData}
            closeModal={() => setOpenForm(false)}
            onFinish={() => setReload((current) => !current)}
            current_customer={current_customer}
          />
        </Box>
      </Modal>
    </>
  );
};

export default SentinelOneConfig;

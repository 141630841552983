import { Breadcrumbs, Tab, Tabs, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import DataGridWithStyles from "../../../Components/DataGrid/DataGridWithStyles";
import { DataContext } from "../../../Context/dataContext";
import { getRequest } from "../../../Helpers/httpRequests";
import { populateDataGrid } from "./functions";
import { useAppSelector } from "../../../Redux/app/hooks";

const ServiceProviderFocus = () => {
  // ----- CONTEXT -----
  const customer = useAppSelector((state) => state.customer);
  const { accessToken } = useContext(DataContext);
  const [activeTab, setActiveTab] = useState("active");

  // ----- STATES -----

  /**
   * contains the datagrid props
   */
  const [dataGridData, setDataGridData] = useState({
    rows: [],
    columns: [],
  });

  // ----- On page load -----

  useEffect(() => {
    const getData = async () => {
      const res = await getRequest(
        `/react/api/${customer.uuid}/serviceprovider/focus`,
        accessToken
      );

      if (res.status === 200) {
        populateDataGrid(res.data.rows, setDataGridData);
      } else {
        console.log(
          "%cerror AdminFocus.jsx useEffect()",
          "color: red; display: block; width: 100%;",
          "Response status not 200"
        );
      }
    };

    getData();
  }, [accessToken]);

  // Filter active and inactive customers based on the activeTab state
  const filteredDataTableRows = dataGridData.rows.filter(
    (row) =>
      (activeTab === "active" && row.active) ||
      (activeTab === "inactive" && !row.active) ||
      (activeTab === "all" && row)
  );

  return (
    <Stack spacing={2}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary">Service Provider</Typography>
        <Typography color="text.primary">Utilization</Typography>
      </Breadcrumbs>

      {/* Page Title */}
      <Typography variant="h3">Utilization Details</Typography>

      {/* Tabs for filtering active and inactive customers */}
      <Tabs
        value={activeTab}
        onChange={(event, newValue) => {
          setActiveTab(newValue);
        }}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="All" value="all" />
        <Tab label="Active" value="active" />
        <Tab label="Inactive" value="inactive" />
      </Tabs>

      {/* Template Grid */}
      <DataGridWithStyles
        name="service-provider-focus"
        autoHeight
        paper
        rows={filteredDataTableRows}
        columns={dataGridData.columns}
        excel
        filter
        columnBar
      />
    </Stack>
  );
};

export default ServiceProviderFocus;

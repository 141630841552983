import React, { useState, useEffect, useContext } from "react";
import {
  Stack,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { postRequest } from "../../../../Helpers/httpRequests";
import { DataContext } from "../../../../Context/dataContext";
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const SentinelConfigForm2 = (props) => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const [securityTools, setSecurityTools] = useState([]);
  const [azureSub, setAzureSub] = useState("");
  const [showAzureSub, setShowAzureSub] = useState(false);
  const [resourceGroup, setResourceGroup] = useState("");
  const [workspaceName, setWorkspaceName] = useState("");
  const [uuid, setUUID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { onFinish, setModalOpen, toolData } = props;
  const [selectedSecurtiyTool, setSelectedSecurityTool] = useState(
    toolData.uuid
  );

  // ----- VARIABLES -----
  // Required Prop

  // ----- FUNCTIONS -----
  const handleSubmit = async () => {
    const data = {
      securityTool: selectedSecurtiyTool,
      azure_subscription_id: azureSub,
      azure_resource_group: resourceGroup,
      sentinel_workspace_name: workspaceName,
      uuid: uuid,
    };

    if (toolData.action === "edit") {
      const result = await postRequest(
        "/react/api/config/securitytoolconfigs/sentinel/edit",
        accessToken,
        data
      );
      if (result.data.status === 200) {
        setModalOpen(false);
        if (onFinish) {
          onFinish();
        }
      } else {
        setErrorMessage(`${result.data.status} - ${result.data.message}`);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000); // wait 5 seconds before clearing the error message
      }
    }

    if (toolData.action === "add") {
      const result = await postRequest(
        "/react/api/config/securitytoolconfigs/sentinel/add",
        accessToken,
        data
      );
      if (result.data.status === 200) {
        setModalOpen(false);
        if (onFinish) {
          onFinish();
        }
      } else {
        setErrorMessage(`${result.data.status} - ${result.data.message}`);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000); // wait 5 seconds before clearing the error message
      }
    }
  };

  // ----- ON PAGE LOAD -----
  useEffect(() => {
    const setForm = async () => {
      setSecurityTools(toolData.securityOptions);
      if (toolData.action === "edit") {
        setSelectedSecurityTool(toolData.tool.uuid);
        setAzureSub(toolData.tool.tool_config.azure_subscription_id);
        setResourceGroup(toolData.tool.tool_config.azure_resource_group);
        setWorkspaceName(toolData.tool.tool_config.sentinel_workspace_name);
        setUUID(toolData.tool.tool_config.uuid);
      }
    };
    setForm();
  }, [accessToken, toolData]);

  return (
    <Stack>
      <Typography variant="h5">
        Microsoft Sentinel Integration Details
      </Typography>
      <Divider style={{ margin: "1rem 0" }} />

      <Stack spacing={2}>
        {/* Simulation */}
        <FormControl>
          <InputLabel id="alert-simulation">Security Tool</InputLabel>
          <Select
            labelId="alert-security-tool"
            id="alert-security-tool-select"
            value={selectedSecurtiyTool ? selectedSecurtiyTool : ""}
            label="Security Tool"
            disabled={toolData.uuid}
            onChange={(e) => setSelectedSecurityTool(e.target.value)}
          >
            {securityTools
              ? securityTools.map((tool) => (
                  <MenuItem key={tool.uuid} value={tool.uuid}>
                    {tool.name}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>

        {/* Azure Subscription ID */}
        <TextField
          required
          id="tool-azure-subscription"
          label="Azure Subscription ID"
          type={showAzureSub ? "text" : "password"}
          value={azureSub}
          onChange={(e) => setAzureSub(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowAzureSub(!showAzureSub)}>
                  {showAzureSub ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* Resource Group */}
        <TextField
          required
          id="tool-azure-resource-group"
          label="Azure Resource Group"
          value={resourceGroup}
          onChange={(e) => setResourceGroup(e.target.value)}
        />

        {/* Workspace Name */}
        <TextField
          required
          id="tool-azure-workspace-name"
          label="Azure Workspace Name"
          value={workspaceName}
          onChange={(e) => setWorkspaceName(e.target.value)}
        />

        <Button
          onClick={handleSubmit}
          variant="contained"
          color={errorMessage ? "error" : "primary"}
        >
          {errorMessage ? errorMessage : "Submit"}
        </Button>
      </Stack>
    </Stack>
  );
};

SentinelConfigForm2.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
};

export default SentinelConfigForm2;

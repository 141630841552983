import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  FaArchive,
  FaPencilAlt,
  FaPlug,
  FaPlus,
  FaTrashAlt,
  FaUndo,
} from "react-icons/fa";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-toastify";
import TextButton from "../../../Components/Buttons/TextButton";
import CrowdstrikeConfigForm from "../../../Components/Forms/AlertValidation/CrowdstrikeConfigForm";
import MicrosoftConfigForm from "../../../Components/Forms/AlertValidation/MicrosoftConfigForm";
import SentinelConfigForm from "../../../Components/Forms/AlertValidation/SentinelConfigForm";
import SentinelOneConfigForm from "../../../Components/Forms/AlertValidation/SentinelOneConfigForm";
import SecurityToolAddForm from "../../../Components/Forms/Config/SecurityToolAddForm/SecurityToolAddForm";
import SecurityToolEditForm from "../../../Components/Forms/Config/SecurityToolEditForm/SecurityToolEditForm";
import FormModal from "../../../Components/Modals/FormModal";
import { DataContext } from "../../../Context/dataContext";
import { getRequest, postRequest } from "../../../Helpers/httpRequests";
import { useAppSelector } from "../../../Redux/app/hooks";
import { COLORS } from "../../../Styles/colors";
import CrowdstrikeConfig from "../../AlertValidation/AVConfig/CrowdstrikeConfig";
import { MicrosoftConfig } from "../../AlertValidation/AVConfig/MicrosoftConfig/MicrsoftConfig";
import SentinelConfig from "../../AlertValidation/AVConfig/SentinelConfig";
import SentinelOneConfig from "../../AlertValidation/AVConfig/SentinelOneConfig";
import UsageModal from "./UsageModal";

const Config_Security_Tools = () => {
  // ----- STATES && CONTEXT -----
  const { accessToken } = useContext(DataContext);
  const darkmode = useAppSelector((state) => state.darkmode);
  const current_customer = useAppSelector((state) => state.customer);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editToolUUID, setEditToolUUID] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [security_tools, setSecurityTools] = useState([]);
  const [predefinedChoices, setPredefinedChoices] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("All");
  const [selectedAvailVendor, setSelectedAvailVendor] = useState("All");
  const [reload, setReload] = useState(false);
  const [toolToDelete, setToolToDelete] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [toolData, setToolData] = useState({
    action: "",
    securityOptions: [],
    tool: {},
    uuid: null,
  });
  const [openMicrosoft, setOpenMicrosoft] = useState(false);
  const [openSentinel, setOpenSentinel] = useState(false);
  const [openCrowdstrike, setOpenCrowdstrike] = useState(false);
  const [openSentinelOne, setOpenSentinelOne] = useState(false);
  const [openMicrosoftIntegration, setOpenMicrosoftIntegration] =
    useState(false);
  const [openSentinelIntegration, setOpenSentinelIntegration] = useState(false);
  const [openSentinelOneIntegration, setOpenSentinelOneIntegration] =
    useState(false);
  const [openCrowdstrikeIntegration, setOpenCrowdstrikeIntegration] =
    useState(false);
  const [inActiveTools, setInactiveTools] = useState([]);
  const [modalText, setModalText] = useState("");
  const [instance, setInstance] = useState("");
  const [connectedStatus, setConnectedStatus] = useState({});

  // ----- VARIABLES -----
  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    top: "40%",
    left: "45%",
    width: "70%",
    height: "55%",
    maxWidth: 900,
    transform: "translate(-25%, -50%)",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const vendorImages = {
    Blackberry: require("../../../Images/security_tools_icons/Blackberry.png"),
    "Carbon Black": require("../../../Images/security_tools_icons/carbon_black.jpg"),
    CheckPoint: require("../../../Images/security_tools_icons/checkpoint.png"),
    Cisco: require("../../../Images/security_tools_icons/cisco.png"),
    Crowdstrike: require("../../../Images/security_tools_icons/crowdstrike.png"),
    Elastic: require("../../../Images/security_tools_icons/elastic.png"),
    McAfee: require("../../../Images/security_tools_icons/mcafee.png"),
    Microsoft: require("../../../Images/security_tools_icons/microsoft.jpg"),
    "Palo Alto Networks": require("../../../Images/security_tools_icons/palo_alto.png"),
    SentinelOne: require("../../../Images/security_tools_icons/sentinel_one.png"),
    Tanium: require("../../../Images/security_tools_icons/tanium.png"),
    "Security Onion": require("../../../Images/security_tools_icons/security_onion.png"),
    Sophos: require("../../../Images/security_tools_icons/sophos.png"),
    Symantec: require("../../../Images/security_tools_icons/symantec.png"),
    Varonis: require("../../../Images/security_tools_icons/varonis.png"),
    WebSense: require("../../../Images/security_tools_icons/websense.png"),
    ServiceNow: require("../../../Images/security_tools_icons/service_now.png"),
    Fortinet: require("../../../Images/security_tools_icons/fortinet.png"),
    Rapid7: require("../../../Images/security_tools_icons/rapid7.png"),
    VMWare: require("../../../Images/security_tools_icons/vmware.png"),
    DeepSeas: require("../../../Images/security_tools_icons/deepseas.jpg"),
    Datto: require("../../../Images/security_tools_icons/datto.jpg"),
    defender: require("../../../Images/security_tools_icons/Windows-defender.png"),
    sentinel: require("../../../Images/security_tools_icons/sentinel.png"),
    default: require("../../../Images/security_tools_icons/default.png"),
    // Add more vendors and their image paths here
  };

  //Functions

  const addDefinedTool = async (tool) => {
    const logDestinationMap = {
      SIEM: "siem",
      "System Console": "console",
      "Other Log Destination": "other",
      "No Logs forwarded or collected": "none",
    };
    let formData = {
      // Corrected the variable name to "formData"
      name: tool.name,
      product: tool.name,
      vendor: tool.vendor, // Assuming you want to use the same value as "product"
      log_destination: logDestinationMap[tool.logDestination],
      access_url: "",
      api_key: "",
    };
    const res = await toast.promise(
      postRequest(
        `/react/api/${current_customer.uuid}/config/securitytools/add`,
        accessToken,
        formData
      ),
      {
        pending: `Adding Security Tool ${formData.name}`,
        success: `${formData.name} Security Added`,
        error: "Something went wrong!",
      }
    );
    if (res.status === 200) {
      setReload((current) => !current);
    } else {
      alert("Something went wrong, Unable to add Tool");
    }
  };

  // Open the edit modal
  const openEditModal = (toolUUID) => {
    setEditToolUUID(toolUUID);
    setEditModalOpen(true);
  };

  // Close the edit modal
  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  // Open the edit modal
  const openAddModal = () => {
    setAddModalOpen(true);
  };

  // Close the edit modal
  const closeEditModal = () => {
    setEditModalOpen(false);
    setEditToolUUID(null);
  };

  const handleVendorChange = (event, newValue) => {
    setSelectedVendor(newValue);
  };

  const handleVendorAvailChange = (event, newValue) => {
    setSelectedAvailVendor(newValue);
  };

  function capitalizeWords(inputString) {
    if (inputString) {
      // Split the input string into words check if there are any words
      const words = inputString ? inputString.split(" ") : null;

      // Capitalize the first letter of each word
      const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });

      // Join the capitalized words into a single string
      const resultString = capitalizedWords.join(" ");

      return resultString;
    } else {
      return "";
    }
  }

  const filteredTools =
    selectedVendor === "All"
      ? security_tools
      : security_tools.filter((tool) => tool.vendor === selectedVendor);

  // const filteredPredefinedChoices =
  //   selectedAvailVendor === "All"
  //     ? predefinedChoices
  //     : predefinedChoices.filter(
  //         (tool) => tool[0].split("|")[1] === selectedAvailVendor,
  //       );

  const filteredPredefinedChoices =
    selectedAvailVendor === "All"
      ? predefinedChoices
      : predefinedChoices.filter((tool) => tool.vendor === selectedAvailVendor);

  const uniqueVendors = [...new Set(security_tools.map((tool) => tool.vendor))];

  const handleAddFormOpen = (tool) => {
    if (tool.product === "Defender") {
      setOpenMicrosoft(true);
    } else if (
      tool.product === "Microsoft Sentinel" ||
      tool.product === "Sentinel"
    ) {
      setOpenSentinel(true);
    } else if (tool.product === "Crowdstrike") {
      setOpenCrowdstrike(true);
    } else if (tool.product === "SentinelOne") {
      setOpenSentinelOne(true);
    }
  };

  const handleIntegrationModal = (tool) => {
    if (tool.product === "Defender") {
      setOpenMicrosoftIntegration(true);
    } else if (
      tool.product === "Microsoft Sentinel" ||
      tool.product === "Sentinel"
    ) {
      setOpenSentinelIntegration(true);
    } else if (tool.product === "Crowdstrike") {
      setOpenCrowdstrikeIntegration(true);
    } else if (tool.product === "SentinelOne") {
      setOpenSentinelOneIntegration(true);
    }
  };

  useEffect(() => {
    const getSecurityTools = async () => {
      const res = await getRequest(
        `/react/api/${current_customer.uuid}/config/securitytools`,
        accessToken
      );
      if (res.status === 200) {
        setPredefinedChoices(res.data.predefined_choices);
        setSecurityTools(res.data.security_tools);
        setInactiveTools(res.data.inactive_tools);
      }
    };

    getSecurityTools();
  }, [current_customer, accessToken, reload]);

  useEffect(() => {
    const fetchConnectionStatus = async (tool) => {
      let endpoint;
      switch (tool.product) {
        case "Defender":
          endpoint = "testmicrosoft";
          break;
        case "Crowdstrike":
          endpoint = "testcrowdstrike";
          break;
        case "Sentinel":
          endpoint = "testsentinel";
          break;
        case "SentinelOne":
          endpoint = "testsentinelOne";
          break;
        default:
          endpoint = "";
      }

      if (endpoint) {
        try {
          const res = await getRequest(
            `/react/api/${current_customer.uuid}/alertvalidation/${endpoint}/${tool.tool_config.uuid}`,
            accessToken
          );
          setConnectedStatus((prevStatus) => ({
            ...prevStatus,
            [tool.uuid]: res?.data?.status || "failed",
          }));
        } catch (error) {
          setConnectedStatus((prevStatus) => ({
            ...prevStatus,
            [tool.uuid]: "failed",
          }));
        }
      }
    };

    security_tools.forEach((tool) => {
      if (tool?.tool_config) {
        fetchConnectionStatus(tool);
      }
    });
  }, [security_tools, current_customer, accessToken]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Home</Link>
        <Typography color="text.primary">Security Tools</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography component={"span"} fontWeight={"bold"} variant="h4">
          Security Tools
        </Typography>
        <TextButton
          onClick={() => openAddModal()}
          tooltip="Create New Tool"
          icon={FaPlus}
        />
      </Box>
      {security_tools.length === 0 ? (
        <Alert severity="info">No Tools Selected</Alert>
      ) : (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5">
              Selected Security Tools ({security_tools.length})
            </Typography>
          </AccordionSummary>

          <Tabs
            value={selectedVendor}
            onChange={handleVendorChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab value="All" label="All" />
            {uniqueVendors.map((vendor) => (
              <Tab key={vendor} value={vendor} label={vendor} />
            ))}
          </Tabs>

          <AccordionDetails>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3} columns={18}>
                {/* Each tool selected here */}
                {filteredTools.map((tool, index) => (
                  <Grid
                    key={index}
                    item
                    sx={{ display: "flex" }}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    xl={6}
                  >
                    <Card
                      sx={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        flexDirection: "column",
                      }}
                    >
                      <CardHeader
                        sx={{
                          background: darkmode
                            ? "linear-gradient(to right, rgb(75, 85, 99), rgb(17, 24, 39))"
                            : "linear-gradient(to right, #e0eafc, #cfdef3)",
                        }}
                        avatar={
                          <Avatar
                            src={
                              tool?.product?.includes("Defender")
                                ? vendorImages.defender
                                : tool?.product?.includes("SentinelOne")
                                ? vendorImages.SentinelOne
                                : tool?.product?.includes("Sentinel")
                                ? vendorImages.sentinel
                                : vendorImages[tool.vendor] ||
                                  vendorImages.default
                            }
                            sx={{
                              backgroundColor: "transparent",
                            }}
                            variant="square"
                          />
                        }
                        title={
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: -1,
                            }}
                          >
                            <Typography variant="h6">{tool.name}</Typography>
                            <IconButton
                              onClick={() => {
                                setToolToDelete(tool);
                                setModalText(
                                  `Are you sure you want to archive ${tool.name} ?`
                                );
                                setInstance("archive");
                                setDeleteModalOpen(true);
                              }}
                              title="Archive SecurityTool"
                            >
                              <FaArchive color={COLORS.error.main} />
                            </IconButton>{" "}
                          </Box>
                        }
                        subheader={
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>
                              {capitalizeWords(tool.vendor)}
                              <br />
                              {capitalizeWords(tool.log_destination)}
                            </Box>
                            <Box>
                              {tool?.tool_config && tool.tool_config && (
                                <Chip
                                  sx={{ marginTop: 1 }}
                                  color="primary"
                                  variant="outlined"
                                  label={`${tool.name} Integration`}
                                />
                              )}
                            </Box>
                          </Box>
                        }
                      />
                      <CardActions sx={{ marginTop: "auto" }}>
                        <Stack
                          direction="row"
                          sx={{ width: "100%" }}
                          justifyContent="space-between"
                          spacing={1}
                        >
                          <Tooltip title="Edit Security Tool">
                            <IconButton
                              onClick={() => openEditModal(tool.uuid)}
                            >
                              <FaPencilAlt />
                            </IconButton>
                          </Tooltip>
                          {tool?.tool_config && (
                            <>
                              {[
                                "Defender",
                                "Crowdstrike",
                                "Sentinel",
                                "SentinelOne",
                              ].includes(tool.product) &&
                                connectedStatus[tool.uuid] !== undefined && (
                                  <Tooltip
                                    title={`Open ${tool.name} Integration`}
                                  >
                                    <IconButton
                                      onClick={() => {
                                        setToolData({
                                          action: "edit",
                                          tool: tool,
                                          uuid: tool.uuid,
                                        });
                                        handleIntegrationModal(tool);
                                      }}
                                    >
                                      <FaPlug
                                        color={
                                          connectedStatus[tool.uuid] ===
                                          "success"
                                            ? COLORS.success.main
                                            : COLORS.error.main
                                        }
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                            </>
                          )}

                          {(tool.product === "Defender" ||
                            tool.product === "Crowdstrike" ||
                            tool.product === "SentinelOne") &&
                            !tool.tool_config && (
                              <Tooltip title="Add Security Tool Integration">
                                <IconButton
                                  onClick={() => {
                                    setToolData({
                                      action: "add",
                                      tool: tool,
                                      uuid: tool.uuid,
                                    });
                                    handleAddFormOpen(tool);
                                  }}
                                >
                                  <FaPlug />
                                </IconButton>
                              </Tooltip>
                            )}
                          {(tool.product === "Microsoft Sentinel" ||
                            tool.product === "Sentinel") &&
                            !tool.tool_config &&
                            filteredTools.some(
                              (item) =>
                                item.product === "Defender" &&
                                item.tool_config !== null
                            ) && (
                              <Tooltip title="Add Security Tool Integration">
                                <IconButton
                                  onClick={() => {
                                    setToolData({
                                      action: "add",
                                      tool: tool,
                                      uuid: tool.uuid,
                                    });
                                    handleAddFormOpen(tool);
                                  }}
                                >
                                  <FaPlus />
                                </IconButton>
                              </Tooltip>
                            )}
                        </Stack>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      <Paper elevation={3} variant="outlined">
        <Box sx={{ p: 2 }}>
          <Typography variant="h5">
            Available Tools ({predefinedChoices.length})
          </Typography>
          <Tabs
            value={selectedAvailVendor}
            onChange={handleVendorAvailChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab value="All" label="All" />
            <Tab value="Blackberry" label="Blackberry" />
            <Tab value="Carbon Black" label="Carbon Black" />
            <Tab value="CheckPoint" label="CheckPoint" />
            <Tab value="Cisco" label="Cisco" />
            <Tab value="Crowdstrike" label="Crowdstrike" />
            <Tab value="Elastic" label="Elastic" />
            <Tab value="McAfee" label="McAfee" />
            <Tab value="Microsoft" label="Microsoft" />
            <Tab value="Palo Alto Networks" label="Palo Alto Networks" />
            <Tab value="SentinelOne" label="SentinelOne" />
            <Tab value="Tanium" label="Tanium" />
            <Tab value="Security Onion" label="Security Onion" />
            <Tab value="Sophos" label="Sophos" />
            <Tab value="Symantec" label="Symantec" />
            <Tab value="Varonis" label="Varonis" />
          </Tabs>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3} columns={18}>
              {filteredPredefinedChoices.map((choice, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    sx={{ display: "flex" }}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    xl={6}
                  >
                    <Card
                      sx={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        flexDirection: "column",
                      }}
                    >
                      <CardHeader
                        sx={{
                          background: darkmode
                            ? "linear-gradient(to right, rgb(75, 85, 99), rgb(17, 24, 39))"
                            : "linear-gradient(to right, #e0eafc, #cfdef3)",
                        }}
                        avatar={
                          <Avatar
                            src={
                              choice.name.includes("Defender")
                                ? vendorImages.defender
                                : choice.name.includes("SentinelOne")
                                ? vendorImages.SentinelOne
                                : choice.name.includes("Sentinel")
                                ? vendorImages.sentinel
                                : vendorImages[choice.vendor] ||
                                  vendorImages.default
                            }
                            sx={{
                              backgroundColor: "transparent",
                            }}
                            variant="rounded"
                          />
                        }
                        title={
                          <Typography variant="h6">
                            {choice.name} {/* First part */}
                          </Typography>
                        }
                        subheader={
                          <>
                            {choice.vendor} {/* Second part */}
                            <br />
                            {choice.logDestination} {/* Third part */}
                          </>
                        }
                      />
                      <CardActions sx={{ marginTop: "auto" }}>
                        <Stack direction="row" sx={{ width: "100%" }}>
                          {/* Add Security Tool */}
                          {!inActiveTools.some(
                            (tool) =>
                              tool.product === choice.name ||
                              tool.name === choice.name
                          ) && (
                            <Tooltip title="Add Security Tool">
                              <IconButton
                                onClick={() => addDefinedTool(choice)}
                              >
                                <FaPlus />
                              </IconButton>
                            </Tooltip>
                          )}

                          {inActiveTools.map((inactiveTool) => {
                            if (
                              inactiveTool.product === choice.name ||
                              inactiveTool.name === choice.name
                            ) {
                              return (
                                <Box key={inactiveTool.uuid}>
                                  <Tooltip title="Restore Inactive Tool Instance">
                                    <IconButton
                                      onClick={() => {
                                        setToolToDelete(inactiveTool);
                                        setModalText(
                                          `Are you sure you want to restore ${inactiveTool.name} archived instance?`
                                        );
                                        setInstance("restore");
                                        setDeleteModalOpen(true);
                                      }}
                                    >
                                      <FaUndo />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete Inactive Tool Instance">
                                    <IconButton
                                      onClick={() => {
                                        setToolToDelete(inactiveTool);
                                        setModalText(
                                          `Are you sure you want to delete ${inactiveTool.name} archived instance?`
                                        );
                                        setInstance("delete");
                                        setDeleteModalOpen(true);
                                      }}
                                    >
                                      <FaTrashAlt color={COLORS.error.main} />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              );
                            }
                            return null;
                          })}
                        </Stack>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Paper>

      {/* Delete Modal Revised */}
      <UsageModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        tool={toolToDelete}
        setTool={setToolToDelete}
        setReload={setReload}
        text={modalText}
        instance={instance}
      />

      {/* Add Modal */}
      <FormModal
        sx={{ display: "flex", flexDirection: "column", width: 500 }}
        open={addModalOpen}
        setOpen={closeAddModal}
      >
        <SecurityToolAddForm
          closeModal={closeAddModal}
          setReload={setReload}
          products={predefinedChoices}
        />
      </FormModal>

      {/* Edit Modal */}
      <FormModal
        sx={{ display: "flex", flexDirection: "column", width: 500 }}
        open={editModalOpen}
        setOpen={closeEditModal}
      >
        <SecurityToolEditForm
          toolUUID={editToolUUID}
          closeModal={closeEditModal}
          setReload={setReload}
        />
      </FormModal>

      {/* Microsoft Add Modal*/}
      <Modal
        open={openMicrosoft}
        onClose={() => setOpenMicrosoft(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <MicrosoftConfigForm
            toolData={toolData}
            setModalOpen={setOpenMicrosoft}
            onFinish={() => setReload((current) => !current)}
          />
        </Box>
      </Modal>

      {/* Microsoft Show Integration Modal */}
      <Modal
        open={openMicrosoftIntegration}
        onClose={() => setOpenMicrosoftIntegration(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <MicrosoftConfig
            toolData={toolData}
            data={toolData.tool}
            setToolData={setToolData}
            setReload={setReload}
            setOpenIntegrationModal={setOpenMicrosoftIntegration}
          />
        </Box>
      </Modal>

      {/* Sentinel Add Modal*/}
      <Modal
        open={openSentinel}
        onClose={() => setOpenSentinel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <SentinelConfigForm
            toolData={toolData}
            setModalOpen={() => setOpenSentinel()}
            onFinish={() => setReload((current) => !current)}
          />
        </Box>
      </Modal>

      {/* Sentinel Show Integration Modal */}
      <Modal
        open={openSentinelIntegration}
        onClose={() => setOpenSentinelIntegration(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <SentinelConfig
            toolData={toolData}
            data={toolData.tool}
            setToolData={setToolData}
            setReload={setReload}
            setOpenIntegrationModal={setOpenSentinelIntegration}
          />
        </Box>
      </Modal>

      {/* Crowdstrike Add Modal*/}
      <Modal
        open={openCrowdstrike}
        onClose={() => setOpenCrowdstrike(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <CrowdstrikeConfigForm
            toolData={toolData}
            setModalOpen={setOpenCrowdstrike}
            onFinish={() => setReload((current) => !current)}
          />
        </Box>
      </Modal>

      {/* Crowdstrike Show Integration Modal */}
      <Modal
        open={openCrowdstrikeIntegration}
        onClose={() => setOpenCrowdstrikeIntegration(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <CrowdstrikeConfig
            toolData={toolData}
            data={toolData.tool}
            setToolData={setToolData}
            setReload={setReload}
            setOpenIntegrationModal={setOpenCrowdstrikeIntegration}
          />
        </Box>
      </Modal>

      {/* SentinelOne Add Modal*/}
      <Modal
        open={openSentinelOne}
        onClose={() => setOpenSentinelOne(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <SentinelOneConfigForm
            toolData={toolData}
            closeModal={() => setOpenSentinelOne(false)}
            onFinish={() => setReload((current) => !current)}
            current_customer={current_customer}
          />
        </Box>
      </Modal>

      {/* SentinelOne Show Integration Modal */}
      <Modal
        open={openSentinelOneIntegration}
        onClose={() => setOpenSentinelOneIntegration(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <SentinelOneConfig
            toolData={toolData}
            data={toolData.tool}
            setToolData={setToolData}
            setReload={setReload}
            setOpenIntegrationModal={setOpenSentinelOneIntegration}
          />
        </Box>
      </Modal>
    </>
  );
};

export default Config_Security_Tools;
